import React from "react";
import styled, { ThemeProvider } from "styled-components";

import TwitterIcon from "../../Components/Icon/TwitterIcon";
import YoutubeIcon from "../../Components/Icon/YoutubeIcon";
import HomepageIcon from "../../Components/Icon/HomepageIcon";

import ArtStationIconLink from "../../Components/Icon/ArtStationIcon";
import BilibiliIconLink from "../../Components/Icon/Bilibili";
import Section from "../../Compositions/AboutSection";
import { unveil, moveInLeft } from "../../Animations/keyframes";
import hexToRgb from "../../utils/helpers";

const MemberTextBlock = styled.div`
  max-width: 50%;
  scrollbar-width: none;
  overflow-y: auto;
  padding-bottom: 100px;

  @media screen and (max-width: 999px) {
    max-width: 100%;
  }
`;
const WorkSection = styled.a`
  display: block;
  padding: 1rem 2rem;
  font-size: 2rem;
  color: white;

  :link,
  :visited {
    text-decoration: none;
  }

  :hover {
    :link {
      text-shadow: 3px 3px 0.5rem rgba(254, 254, 254, 0.7);
    }
  }
`;

const MemberProfile = styled.div`
  display: flex;
  flex-direction: column;
  overflow:hidden;
    padding: 2rem;
    
    width: 80%;
    max-width: 700px;
    height: 80vh;
    max-height:800px;
    
    margin: 0 auto;
    border-radius: 0.5rem;
    box-shadow: 0 1rem 2rem rgba(0,0,0, .5); 
    text-shadow: 1px 1px 3px  #bbbbbb;

    position: relative;
    z-index : 1;
    overflow: hidden;

   
    background-attachment: local, scroll;


    @media screen and (max-width: 999px){
      background: linear-gradient(to right bottom, rgba(${(props) =>
        hexToRgb(props.theme.dark)}, 0.8) 50%, rgba(0,0,0, 0.9)) 99%, 
      url('${window.location.origin}/assets/about-${(props) =>
  props.name}-half-2x.${(props) =>
  props.imageType === "png" ? "png" : "webp"}') no-repeat;   //we technically do not need this if-else statement anymore but
        background-size: cover;
        background-position: center;
    }

    @media screen and (min-width: 1000px){
      background: url('${window.location.origin}/assets/about-${(props) =>
  props.name}-half-2x.${(props) =>
  props.imageType === "png" ? "png" : "webp"}') no-repeat;
  background-size: cover;
  background-position: center top ;

      ::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 200%;
        height: 100%;
        background-image:
        linear-gradient(to right, ${(props) =>
          props.theme.dark} 60%,  transparent 60%);
        
        transition: transform 0.4s;
        z-index: -1;
        animation-delay: 1s;
        animation-name: ${unveil};
        animation-duration: 0.5s;
        animation-timing-function: ease-out;
        animation-fill-mode: forwards; 
      }

    ::after {
      content  : "";
      position : absolute;
      z-index  : 1;
      bottom   : 0;
      left     : 0;
      pointer-events   : none;
      background-image : linear-gradient(to bottom,  rgba(
        ${(props) => hexToRgb(props.theme.dark)}, 0), 
        rgba(
          0,0,0, 0.4));
      width    : 100%;
      height   : 100px;
    }
		}

 
`;

const MemberDescription = styled.div`
  flex-grow: 1;
  font-size: 2rem;
  padding-top: 2rem;
  color: white;
  animation-name: ${moveInLeft};
  animation-duration: 0.9s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  @media screen and (max-width: 850px) {
    max-width: 100%;
  }
`;

const MemberName = styled.div`
  font-size: 7rem;
  color: white;
  animation-name: ${moveInLeft};
  animation-duration: 0.8s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  text-shadow: 2px 1px 2px ${(props) => props.theme.dark};
`;

const MemberRank = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  font-size: 1.9rem;
  color: white;
  height: 4.5rem;
  width: 15rem;
  background: ${(props) => props.theme.rankPlate};
  box-shadow: 0.5rem 1rem 2rem rgba(0, 0, 0, 0.7);
  text-align: center;
  padding-top: 7px;
  border-radius: 2px;
`;

const IconTray = styled.div`
  max-width: 330px;
  height: 10rem;
  border-bottom: 1px solid ${(props) => props.theme.highlight};
`;

const AboutCard = ({
  name,
  rank,
  displayName,
  description,
  socialMedia,
  worksTitle,
  works,
  theme,
  imageType,
  children,
}) => {
  const instructorTheme = {
    dark: theme.sub_dark,
    med: theme.sub,
    light: theme.font_light,
    rankPlate: theme.sub_bright,
  };

  const memberTheme = {
    dark: theme.main,
    med: theme.sub_bright,
    light: theme.font_light,
    rankPlate: theme.sub_dark,
  };

  const getTheme = (memberType) =>
    memberType === "IV Instructor" ? instructorTheme : memberTheme;

  return (
    <ThemeProvider theme={getTheme(rank)}>
      <MemberProfile name={name} imageType={imageType}>
        <MemberRank>{rank}</MemberRank>
        <MemberName>
          {displayName}
          <IconTray>
            {socialMedia.twitter && <TwitterIcon url={socialMedia.twitter} />}
            {socialMedia.youtube && <YoutubeIcon url={socialMedia.youtube} />}
            {socialMedia.homepage && (
              <HomepageIcon url={socialMedia.homepage} />
            )}
            {socialMedia.artstation && (
              <ArtStationIconLink url={socialMedia.artstation} />
            )}
            {socialMedia.bilibili && (
              <BilibiliIconLink url={socialMedia.bilibili} />
            )}
          </IconTray>
        </MemberName>
        <MemberTextBlock>
          <MemberDescription>{description}</MemberDescription>
          {worksTitle &&
            worksTitle.map((title, index) => {
              return (
                <Section title={title} key={`S-${index}`}>
                  {works[index].map((work, workIndex) =>
                    work.url ? (
                      <WorkSection
                        key={`w-${workIndex}`}
                        href={work.url}
                        target="_blank"
                      >
                        {work.title}
                      </WorkSection>
                    ) : (
                      <WorkSection isOdd={true} key={`w-${workIndex}`}>
                        {work}
                      </WorkSection>
                    )
                  )}
                </Section>
              );
            })}
        </MemberTextBlock>
      </MemberProfile>
      {children}
    </ThemeProvider>
  );
};

export default AboutCard;
