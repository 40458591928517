import styled, { keyframes } from "styled-components";
import React from "react";
import hexToRgb from "../../utils/helpers";

// Create the keyframes
const moveInLeft = keyframes`
    0% {
        opacity: 0;
        transform: translateX(-5rem);
    }

    80% {
        transform: translateX(1rem);
    }
    100% {
        opacity: 1;
        transform: translateX(0); //look exactly like now
    }
`;

const moveInRight = keyframes`
    0% {
        opacity: 0;
        transform: translateX(3rem);
    }

    80% {
        transform: translateX(-1rem);
    }
    100% {
        opacity: 1;
        transform: translateX(0); //look exactly like now
    }
`;

const HeadingPrimary = styled.h1`
  //h1 helps google figure out what our title is
  padding-top: 9rem;
  color: ${(props) => props.theme.font_light};

  backface-visibility: hidden; //backpart of element is not show, for animation to not be shaky
  margin-bottom: 6rem;
  text-align: center;
  margin: 0 auto;

  max-width: 70vw;
`;

const HeaderPrimaryMain = styled.div`
  //block level elements take up entire width, and create line-breaks before and after!
  display: block;
  font-size: 6rem;
  letter-spacing: calc(1.2rem + 0.6vw);
  font-weight: 200;
  text-transform: uppercase;
  margin: 0 auto;

  @media (max-width: 450px) {
    font-size: 30px;
    letter-spacing: 2rem;
  }

  animation-name: ${moveInLeft};
  animation-duration: 2.5s;
  animation-timing-function: ease-out;
`;

const MainPageHeader = styled(HeaderPrimaryMain)`
  font-size: calc(3rem + 2vw);
  letter-spacing: calc(1.2rem + 0.6vw);
  margin: 0 auto;
  text-shadow: 3px 3px 0.5rem
    rgba(${(props) => hexToRgb(props.theme.font_dark)}, 0.7);

  @media (min-width: 1920px) {
    font-size: 7rem;
  }
`;

const HeadingPrimarySub = styled.span`
  //block level elements take up entire width, and create line-breaks before and after!
  display: block;
  padding: 2.5rem 0;
  font-size: 2rem;
  font-weight: 200;
  letting-spacing: 1.74rem;
  animation: ${moveInRight} 1s ease-out;
  text-transform: uppercase;

  @media (max-width: 450px) {
    font-size: 12px;
  }
`;

export const MainHeader = ({ title, sub }) => {
  return (
    <HeadingPrimary>
      <MainPageHeader>{title}</MainPageHeader>
      <HeadingPrimarySub>{sub}</HeadingPrimarySub>
    </HeadingPrimary>
  );
};

const HeaderText = ({ title, sub }) => (
  <HeadingPrimary>
    <HeaderPrimaryMain>{title}</HeaderPrimaryMain>
    {sub && <HeadingPrimarySub>{sub}</HeadingPrimarySub>}
  </HeadingPrimary>
);

export default HeaderText;
