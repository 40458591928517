import React from 'react';
import styled from 'styled-components';
import Background from '../../Components/Background';
import { Link } from 'react-router-dom';

import { MainHeader } from '../../Components/HeaderText';
import { moveInBottom2 } from '../../Animations/keyframes'

const HeaderWrap = styled(Background)`
    clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%); 

    position: relative;
    height: 70vh;
`

const TextBox = styled.div`
    position: absolute;
    top: 40%;
    left: 50%;
    text-align: center; //button is 'text', will be centered;
    transform: translate(-50%, -50%); //the 50% is in relation to themselves
`

const Button = styled(Link)`
    :link, :visited {
        //state of button selector, special condition
        text-transform: uppercase;
        text-decoration: none;
        padding: 1.5rem 4rem;
        //is inline-element, bc just text so:
        display: inline-block;
        border-radius: 10rem;

        font-size: 16px;

        transition: all 0.2s; //animate all

        position: relative; //for other buttons to anchor to.
        
    }

    &::after {
        content: ""; //have to specify to appear
        display: inline-block; //like button;
        height: 100%;
        width: 100%;
        border-radius: 10rem;

        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;

        //initiatl state for hover:after:
        transition: all .4s;
    }


    &:hover {
        transform: translateY(-.3rem); //Y axis move from top to bottom in html
        box-shadow: 0 1rem 2rem rgba(0,0,0, .2); //none on x, 10px on y 20px blur and subtly black
    
        &::after {
            transform: scaleX(1.4) scaleY(1.6);
            opacity: 0;
            
        }
    
    }

    :active {
        transform: translateY(-1rem); // in relative to link state.
        box-shadow: 0 .5rem 1rem rgba(0,0,0, .2);

    }

   
`

const ButtonWhite = styled(Button)`
    animation: ${moveInBottom2} .5s ease-out .75s; //delay .75 seconds
    animation-fill-mode: backwards; //auto apply 0% styles before animation start!
    background-color: ${(props) => props.theme.sub};
    color: white;

    :after {
        background-color: ${(props) => props.theme.sub};
    }

`




const Header = () => (
    <HeaderWrap>
        <TextBox>
            <MainHeader
                title="Iron Vertex"
                sub="VTuber Production Studio"
            />
            <ButtonWhite to="/about">Learn More</ButtonWhite>
        </TextBox>
    </HeaderWrap>
)

export default Header;
