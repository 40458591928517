import { keyframes } from 'styled-components';

export const openDiv = keyframes`
	0% {
        // clip-path : circle(30px at 50% 50%);
        opacity: 0
	}

    // 85% {
    //     transform: scale(1.1) rotate(2deg);
    // }

    // 90% {
    //     transform: rotate(1.5deg);
    // }
 
    100% {
      opacity: 1;
    }

`

export const moveInLeft = keyframes`
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0;
        transform: translateX(-5rem);
    }

    80% {
        opacity: 0.2;
        transform: translateX(1rem);
    }
    100% {
        opacity: 1;
        transform: translateX(0); //look exactly like now
    }
`

export const angleMoveDownWhite = keyframes`
    0% {
        clip-path: polygon(54px 0px, 41.68% 0px, -1px 0px);
    }

    100% {
        clip-path: polygon(54px 79px, 41.68% 0px, -1px 0px);
    }
    
`

export const angleMoveDownPurple = keyframes`
    0% {
        clip-path: polygon(53.74% -1px, 99.9% 2px, 86.86% 0);
    }

    100% {
        clip-path: polygon(53.74% -1px, 99.9% 2px, 86.86% 6.60em);
    }
    
`


export const unveil = keyframes`
    0% {
         transform: translateX(0%);
    }

    100%{
         transform: translateX(-30%);
    }
`

export const moveInBottom = keyframes`
0% {
    transform:translateX(-100%) translateY(-100%) rotate(30deg);
}
80% {transform:translateX(-100%) translateY(-100%) rotate(30deg);}
100% {transform:translateX(100%) translateY(100%) rotate(30deg);}
`

export const moveInBottom2 = keyframes`
    0% {
        opacity: 0;
        transform: translateY(3rem);
    }

    80% {
        transform: translateY(10px);
    }
    100% {
        opacity: 1;
        transform: translateY(0); //look exactly like now
    }
`


export const shine = keyframes`
0%      { transform:translateX(-100%) translateY(-100%) rotate(30deg);}
1%     {transform:translateX(-100%) translateY(-100%) ;}
100%    {transform:translateX(100%) translateY(100%) rotate(120deg);}
`



export const moveOut = keyframes`
    0% {
        clip-path: polygon(15rem 0, 100% 0, 100% 100%, 0 100%)
         filter: blur(1px) brightness(100%);
        
    }

    100% {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
`

export const moveIn = keyframes`
    0% {
         clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        filter: blur(3px) brightness(80%);
        transform: scale(1.05);
    }

    100% {
        clip-path: polygon(15rem 0, 100% 0, 100% 100%, 0 100%);
       filter: blur(1px) brightness(100%);
       transform: scale(1);
    }
`

export const spinIn = keyframes`
    25% {  
        transform: rotate(45deg) scale(0.8);
    }
    100% { 
        transform: rotate(1800deg) scale(0.01);

    }
`
export const spinOut = keyframes`
    0% {
        opacity: 1;
        transform: scale(0.2);
        fill: white;
         filter: none;
    }
    12% {
        fill: white;
    }
    25% {  
        transform: rotate(-145deg) scale(0.5);
        
        
    }
    100% {
        opacity: 1; 
        transform: rotate(-1800deg) scale(1);
        filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
    }
`

export const fadeOut = keyframes`
    25% { opacity : .25; }
    100% { opacity: 0;}
`
