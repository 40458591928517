const staffInformation = {
  canain: {
    id: 0,
    displayName: "Canain",
    name: "canain",
    rank: "IV CTO",
    description: "Canain is the CTO of Iron Vertex and leads IV corporate operations. He is a Software Engineer who worked in big tech and small startups before being bored and started building way more interesting VTuber tech. Canain loves architecting anything software related with web & game tech his specialty and a big interest in ML & VR.",
    socialMedia: {
      twitter: "https://twitter.com/CanainMC",
      homepage: "https://canain.com",
    },
    worksTitle: ["Software Works"],
    works: [
      [
        {
          title: "VTuber Market - vtuber.gg",
          url: "https://vtuber.gg",
        },
        {
          title: "IV Collab Tool",
          url: "https://twitter.com/iron_vertex/status/1527406408681021440",
        },
        {
          title: "Winter 2021 Snowglobe Event",
          url: "https://vtuber.gg/snowglobe",
        },
        {
          title: "GitHub - Canain",
          url: "https://github.com/Canain",
        },
      ],
    ],
  },
  recca: {
    id: 0,
    displayName: "Recca",
    name: "recca",
    rank: "IV Staff",
    description: "Recca started admiring Live2D in late 2016, and ended up loving it over the coming years. Their curiosity introduced Vtubers alongside content creation and has been longtime fan. With passion for the Live2D space, Recca joined IV in early 2022 as an Assistant Project Manager!",
    socialMedia: {},
    worksTitle: ["Video Editing"],
    works: [
      [
        {
          title: "IV Collab Tool Preview Video",
          url: "https://www.youtube.com/watch?v=ByJa4plZavU",
        },
      ],
    ],
  },
};

export default staffInformation;
