import styled from "styled-components";
import { ReactComponent as YoutubeIcon } from "../youtube-logo.svg";
import React from "react";
import SVGLink from "../Base/";

const YoutubeIconStyled = styled(YoutubeIcon)`
  margin-top: 2rem;
  height: 5rem;
  width: 6rem;
  fill: white;

  :hover {
    cursor: pointer;
    transform: scale(1.05);
    transition: fill 0.2s cubic-bezier(0.19, 1, 0.22, 1);
  }
`;

const YoutubeIconStyledLink = ({ url }) => (
  <SVGLink href={url} target="_blank">
    <YoutubeIconStyled />
  </SVGLink>
);

export default YoutubeIconStyledLink;
