import styled from "styled-components";
import React from "react";
import { ReactComponent as ArtStationIcon } from "../artstation-logo.svg";
import SVGLink from "../Base/";

const ArtStationIconStyled = styled(ArtStationIcon)`
  margin-top: 2rem;
  height: 6rem;
  fill: white;
`;

const ArtStationIconLink = ({ url }) => (
  <SVGLink href={url} target="_blank">
    <ArtStationIconStyled />
  </SVGLink>
);

export default ArtStationIconLink;
