import styled from "styled-components";
import React from "react";
import { ReactComponent as TwitterIcon } from "../Twitter_Logo_WhiteOnImage.svg";
import SVGLink from "../Base/";

const TwitterIconStyled = styled(TwitterIcon)`
  margin-top: 2rem;
  height: 6rem;
  fill: white;

  :hover {
    cursor: pointer;
    transform: scale(1.05);
    transition: fill 0.2s cubic-bezier(0.19, 1, 0.22, 1);
  }
`;

const TwitterIconLink = ({ url }) => (
  <SVGLink href={url} target="_blank">
    <TwitterIconStyled />
  </SVGLink>
);

export default TwitterIconLink;
