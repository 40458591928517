import React from "react";
import { Redirect } from "react-router-dom";
import styled from "styled-components";

import AboutParentContainer from "../../Compositions/AboutParentContainer";
import ReturnButton from "../../Compositions/ReturnButton";
import memberInformation from "../../utils/MemberInfo/index";
import instructorInformation from "../../utils/InstructorInfo/index";
import staffInformation from "../../utils/StaffInfo/index";
import alumniInformation from "../../utils/AlumniInfo/index";


import Gallery from "../../Compositions/Gallery";
import { ContentHeader, ContentWrapper } from "../../Compositions/AboutSection";

const ContentHeaderStyled = styled(ContentHeader)`
  margin: 0 auto;
  margin-top: 5rem;
  width: 80%;
  max-width: 700px;
`;

const ContentWrapperStyled = styled(ContentWrapper)`
  margin: 0 auto;
  border: none;
  margin-top: 2rem;
  width: 80%;
  max-width: 700px;
`;

const About = ({ id }) => {
  let member;
  if (memberInformation[id]) {
    member = memberInformation[id];
  } else if (instructorInformation[id]) {
    member = instructorInformation[id];
  } else if (staffInformation[id]) {
    member = staffInformation[id];
  } else if (alumniInformation[id]) {
    member = alumniInformation[id];
  }

  if (!member) {
    return <Redirect push to={"/"} />;
  }

  console.log("Entire member object", member);
  return (
    <AboutParentContainer member={member}>
      {(member.clients || member.portfolio) && (
        <>
          <ContentHeaderStyled>
            {member.clients ? "IV Works" : "Portfolio"}
          </ContentHeaderStyled>
          <ContentWrapperStyled>
            <Gallery
              gallery={member.clients ? member.clients : member.portfolio}
              index={0}
              animator={member.name}
              modaltype={"tier"}
            />
            {member.name === "brian" && (
              <>(If you want more... ask him yourself, the dev got bored.)</>
            )}
          </ContentWrapperStyled>
        </>
      )}
      <ReturnButton route={"/about"} />
    </AboutParentContainer>
  );
};

export default About;

/**
 *  <Section title={"IV Works"}>
        <Gallery
          gallery={member.clients}
          index={0}
          animator={member.name}
          modaltype={"tier"}
        />
        {member.name === "brian" && (
          <>(If you want more... ask him yourself, the dev got bored.)</>
        )}
      </Section>


      <AboutPage>
          <MemberIntroduction>

          </MemberIntroduction>
          <IVWorks/>
          <ReturnButton/>
      </AboutPage>
 * 
 */
