import React from "react";
import styled from "styled-components";

import { TWITTER_URL, YOUTUBE_URL } from "../../utils/Links";
import { ReactComponent as Icon } from "../../Components/Icon/logo_flat.svg";

import TwitterIcon from "../../Components/Icon/TwitterIcon";
import YoutubeIconStyled from "../../Components/Icon/YoutubeIcon";
import { Link } from "react-router-dom";

const FooterStyled = styled.footer`
  background: ${(props) => props.theme.shadow};
  margin-top: 4em;
  font-weight: 200;
`;

const FooterFlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: center;
  padding-top: 2rem;

  max-width: 1400px;
  margin: 0 auto;
`;

const FlexItemLeft = styled.div`
  padding: 2rem;
  width: 300px;
  flex-grow: 10;
  flex-shrink: 0;
`;

const FlexItem = styled.div`
  padding: 2rem;
  width: 250px;
  flex-grow: 1;
`;

const FooterHeader = styled.div`
  color: ${(props) => props.theme.font_dark};
  letter-spacing: 1rem;
  text-align: center;
  font-size: 5rem;
`;

const SubHeader = styled.div`
  color: ${(props) => props.theme.font_dark};
  font-size: 2rem;
  border-bottom: 1px solid ${(props) => props.theme.sub};
  text-transform: uppercase;
`;

const CopyRight = styled.div`
  color: ${(props) => props.theme.font_light};
  text-align: center;
`;

const LinkStyled = styled(Link)`
  display: block;

  color: ${(props) => props.theme.font_light};
  font-size: 1.65rem;
  padding-top: 2rem;
  padding-left: auto;

  :link,
  :visited {
    text-decoration: none;
  }

  :hover,
  :active {
    transform: translateX(3px);
    color: white;
  }

  @media (max-width: 500px) {
    font-size: 16px;
  }
`;

const IconStyled = styled(Icon)`
  display: block;
  margin: 0 auto;
  width: 7rem;
  height: 7rem;
  stroke: ${(props) => props.theme.highlight};
  stroke-width: 5;
  fill: none;
`;
const Footer = () => (
  <FooterStyled>
    <FooterFlexWrapper>
      <FlexItemLeft>
        <IconStyled />
        <FooterHeader>Iron Vertex</FooterHeader>
        <CopyRight>Copyright 2022 Iron Vertex Inc.</CopyRight>
        <CopyRight>Banner Illustration by SuteinuA</CopyRight>
      </FlexItemLeft>
      <FlexItem>
        <SubHeader>Site Guide</SubHeader>
        <LinkStyled to="/">Home</LinkStyled>
        <LinkStyled to="/about">Members</LinkStyled>
        <LinkStyled to="" as={"a"} href={`/tier#top`}>
          Works
        </LinkStyled>
        <LinkStyled to="/tutorial">Tutorials</LinkStyled>
        <LinkStyled to="/contact">Contact</LinkStyled>
        <LinkStyled to="/faq">FAQ</LinkStyled>
      </FlexItem>
      <FlexItem>
        <SubHeader>Social Media</SubHeader>
        <TwitterIcon url={TWITTER_URL} />
        <YoutubeIconStyled url={YOUTUBE_URL} />
      </FlexItem>
    </FooterFlexWrapper>
  </FooterStyled>
);

export default Footer;
