const memberInformation = {
  brian: {
    id: 0,
    displayName: "Brian Tsui",
    name: "brian",
    rank: "IV Leader",
    imageType: "webp",
    description: `Brian started messing around with Live2D since 2015, and accidentally made it into a career while he was a full time software engineer. As much as it was an accident, Brian is known to those around him that he trains for 60 - 90 hours per week in Live2D. When he is not at work, he is trying to clear off all his overdue commissions and tutorials.`,
    socialMedia: {
      twitter: "https://twitter.com/BrianTsuii",
      youtube: "https://www.youtube.com/c/briantsui",
      artstation: "https://www.artstation.com/brian_tsui",
      bilibili: "https://space.bilibili.com/381261121",
    },
    worksTitle: ["Commercial Works", "Live2D", "Tutorials", "NDA"],
    works: [
      [ 
        {
          title: "2020: Porter Robinson MV - \"Mirror\" : Character Rigging & Animatiom ",
          url:
            "https://www.youtube.com/watch?v=PkiIPzG37vQ",
        },
        {
          title: "2020: Azur Lane Operation Sirens MV - \"Signal\" : Character Rigging & Animation ",
          url:
            "https://twitter.com/AzurLane_EN/status/1341338988917837826",
        },
      ],
      [
        "Live2D Evangelist",
        "Live2D Cubism 2.0 UI Translator",
        "Live2D Cubism 3.0 UI Translator",
        "Live2D Cubism 4.0 UI Translator",
      ],
      [
        {
          title: "2015: Live2D 2.0 Unofficial Tutorials",
          url:
            "https://www.youtube.com/watch?v=eWf0GUk0NEw&list=PLhha8OGApHGM4B8gk7uaqu_LjXAeSuRKP",
        },
        {
          title: "2017: Live2D 3.0 Official English Tutorials Ep.01 - 06",
          url:
            "https://www.youtube.com/watch?v=Z-GxdkXNroI&list=PLqbLt-S6_fh7pxcasSMzeDjc9K5KPWE9H&index=1",
        },
        {
          title: "2019: Live2D 4.0 Official - Introduction to Technical Tools",
          url:
            "https://www.youtube.com/watch?v=Wr37T3S6LQU&list=PLhha8OGApHGMkC4F41zN3fFaSBRu-YIrc&index=2&t=1s",
        },
      ],
      ["Crico JP: Contracted Animator", "Riot Games: Live2D Consultant"],
    ],
    clients: [
      {
        name: "Hoshinomiya Miya",
        artist: { name: "Ikataruto", url: "https://twitter.com/ikataruto" },
        src: "/assets/gallery-brian-00.webp",
        site: "https://space.bilibili.com/402105315/video",
        url: "https://www.youtube.com/watch?v=9RQoz9qrFo4",
        tier: 4,
      },
      {
        name: "Fanart - Fate: Nero Bride",
        artist: { name: "Saruei", url: "https://twitter.com/Saruei_" },
        src: "/assets/gallery-brian-01.webp",
        url: "https://www.youtube.com/watch?v=tPL0riLpg1c",
        tier: 5,
      },
      {
        name: "Yamagami Chiro",
        artist: { name: "Canarinu", url: "https://twitter.com/canarinu_kmes" },
        site: "https://twitter.com/yamagamichiro",
        src: "/assets/gallery-brian-02.webp",
        url: "https://www.youtube.com/watch?v=0RsVkL0yVbQ",
        tier: 4,
      },
      {
        name: "Fanart - Fate: MXH Alter",
        artist: { name: "Saruei", url: "https://twitter.com/Saruei_" },
        src: "/assets/gallery-brian-03.webp",
        url: "https://www.youtube.com/watch?v=69EjtxzDAXg",
        tier: 5,
      },
      {
        name: "Ayana Nana",
        artist: { name: "Yatukiyura", url: "https://twitter.com/yatukiyura" },
        site: "https://twitter.com/AyanaxNana",
        src: "/assets/gallery-brian-04.webp",
        url: "https://www.youtube.com/watch?v=gCnHfcSMSxg",
        tier: 4,
      },
      {
        name: "Project Idol City",
        artist: {
          name: "Unknown",
          url: "https://www.youtube.com/watch?v=fIr5eN54Bl4",
        },
        site: "https://www.facebook.com/IDOLCITY.DREAM/?hc_location=ufi",
        src: "/assets/gallery-brian-05.webp",
        url: "https://www.youtube.com/watch?v=fIr5eN54Bl4",
        tier: 3,
      },
      {
        name: "Kawaii Nihongo: Yuji",
        artist: { name: "Rosuuri", url: "https://twitter.com/rosuuri" },
        site: "https://twitter.com/kawaiiNihongo",
        src: "/assets/gallery-brian-06.webp",
        url: "https://www.youtube.com/watch?v=VeD83d-f5HI",
        tier: 2,
      },

      {
        name: "Kawaii Nihongo: Riko",
        artist: { name: "Rosuuri", url: "https://twitter.com/rosuuri" },
        site: "https://twitter.com/kawaiiNihongo",
        src: "/assets/gallery-brian-07.webp",
        url: "https://www.youtube.com/watch?v=YxvOIdqIsBo",
        tier: 2,
      },

      {
        name: "Wula",
        artist: { name: "Gugu", url: "https://twitter.com/LazlnEssGugu" },
        site: "https://space.bilibili.com/610390/",
        src: "/assets/gallery-brian-08.webp",
        url: "https://www.youtube.com/watch?v=zZYwqWfpPBQ",
        tier: 4,
      },
      // {
      //   name: "Ash",
      //   artist: { name: "Unknown", url: "https://twitter.com/Sugoi_Ash" },
      //   site: "https://twitter.com/Sugoi_Ash",
      //   src: "/assets/gallery-brian-09.webp",
      //   url: "https://www.youtube.com/watch?v=7WR_7yp5l8s",
      //   tier: 2,
      // },
      {
        name: "Agari Raburi",
        artist: { name: "Bison", url: "https://twitter.com/bisonham" },
        site: "https://space.bilibili.com/3821157",
        src: "/assets/gallery-brian-11.webp",
        url: "https://www.youtube.com/watch?v=l7BZSndosd0",
        tier: 4,
      },
      {
        name: "Shirakami Haruka",
        artist: { name: "Bison", url: "https://twitter.com/bisonham" },
        site: "https://space.bilibili.com/477332594/",
        src: "/assets/gallery-brian-12.webp",
        url: "https://www.youtube.com/watch?v=xea8vvLgAh8",
        tier: 4,
      },
      {
        name: "Yowkow",
        artist: {
          name: "Usatska Eiji",
          url: "https://twitter.com/usatsukaeiji",
        },
        site: "https://twitter.com/yowkow",
        src: "/assets/gallery-brian-13.webp",
        url: "https://www.youtube.com/watch?v=ijVyx_PfEaE",
        tier: 1,
      },
      {
        name: "Shirakami Haruka [Summer 2020]",
        artist: { name: "Bison", url: "https://twitter.com/bisonham" },
        site: "https://space.bilibili.com/477332594/",
        src: "/assets/gallery-brian-14.webp",
        url: "https://www.youtube.com/watch?v=hC2NTI4aFVQ",
        tier: 4,
      },
      {
        name: "Aki Rinco",
        artist: { name: "Bison", url: "https://twitter.com/bisonham" },
        site: "https://space.bilibili.com/479633069/",
        src: "/assets/gallery-brian-15.webp",
        url: "https://www.youtube.com/watch?v=9bQztKwyMrQ",
        tier: 4,
      },
      {
        name: "Pudding",
        artist: { name: "Bison", url: "https://twitter.com/bisonham" },
        site: "https://space.bilibili.com/416622817/",
        src: "/assets/gallery-brian-16.webp",
        url: "https://www.youtube.com/watch?v=XjmsaHmH4U0",
        tier: 4,
      },

      {
        name: "Soutsuki Yuna",
        artist: { name: "Bison", url: "https://twitter.com/bisonham" },
        site: "https://space.bilibili.com/62359",
        src: "/assets/gallery-brian-17.webp",
        url: "https://www.youtube.com/watch?v=4LZPIRtVzh0",
        tier: 4,
      },
      {
        name: "Soutsuki Lia",
        artist: { name: "Bison", url: "https://twitter.com/bisonham" },
        site: "https://space.bilibili.com/62359",
        src: "/assets/gallery-brian-18.webp",
        url: "https://www.youtube.com/watch?v=KCvVLr64COQ",
        tier: 4,
      },
      {
        name: "Anekawa Chiyuki",
        artist: { name: "湯気", url: "https://twitter.com/ygmkmk" },
        site: "https://twitter.com/chiyuki_ankw",
        src: "/assets/gallery-brian-19.webp",
        url: "https://www.youtube.com/watch?v=anWDTG6Dkds",
        tier: 4,
      },
      {
        name: "Dear Monsters - Imbriss",
        artist: { name: "Jouvru", url: "https://twitter.com/Jouvru" },
        site: "https://www.patreon.com/lovenuts",
        src: "/assets/gallery-brian-20.webp",
        url: "https://www.youtube.com/watch?v=MwCIkpuRoDk",
        tier: 5,
      },
      {
        name: "Chai",
        artist: { name: "Yueko", url: "https://twitter.com/yueko__" },
        site: "https://twitter.com/chaikiyo",
        src: "/assets/gallery-brian-21.webp",
        url: "https://www.youtube.com/watch?v=TydfztuSS8A",
        tier: 3,
      },
      {
        name: "Azur Lane [Official] - Azur Lane Operation Siren MV (2020)",
        artist: { name: "Doomfest", url: "https://twitter.com/Doomfest" },
        site: "https://twitter.com/AzurLane_EN",
        src: "/assets/gallery-brian-22.webp",
        url: "https://www.youtube.com/watch?v=wao7kUZoP9w",
        tier: 5,
      },
      {
        name: "Porter Robinson [Official] - Mirror MV (2020): Avatar Animation",
        artist: { name: "Satekorekara", url: "https://twitter.com/satekorekara" },
        site: "https://twitter.com/porterrobinson",
        src: "/assets/gallery-brian-23.webp",
        url: "https://www.youtube.com/watch?v=PkiIPzG37vQ",
        tier: 5,
      },
      {
        name: "Rosu",
        artist: { name: "Rosuuri", url: "https://twitter.com/rosuuri" },
        site: "https://twitter.com/ganbarosu",
        src: "/assets/gallery-brian-24.webp",
        url: "https://www.youtube.com/watch?v=4cCo8KaNUSg",
        tier: 3,
      },
      {
        name: "Rosu Alt",
        artist: { name: "Rosuuri", url: "https://twitter.com/rosuuri" },
        site: "https://www.twitch.tv/rosuuri",
        src: "/assets/gallery-brian-25.webp",
        url: "https://www.youtube.com/watch?v=2Uv3QMSni_c",
        tier: 3,
      },
      {
        name: "Froot",
        artist: { name: "Froot", url: "https://twitter.com/LichVtuber" },
        site: "https://www.twitch.tv/apricot",
        src: "/assets/gallery-brian-26.webp",
        url: "https://www.youtube.com/watch?v=1lDi8dkBKPI",
        tier: 2,
      },
      {
        name: "Froot",
        artist: { name: "Froot", url: "https://twitter.com/LichVtuber" },
        site: "https://www.twitch.tv/apricot",
        src: "/assets/gallery-brian-27.webp",
        url: "https://www.youtube.com/watch?v=Y6WTEmCz1GI",
        tier: 2,
      },
      {
        name: "Nana",
        artist: { name: "Stinah", url: "https://twitter.com/ex_pulse" },
        site: "https://www.twitch.tv/apricot",
        src: "/assets/gallery-brian-28.webp",
        url: "https://www.youtube.com/watch?v=Q9FvqZDATEo",
        tier: 3,
      },
      {
        name: "Azur Lane [Official] - Azur Lane April Fools Login (2021)",
        artist: { name: "JanyHero", url: "https://twitter.com/Janyhero" },
        site: "https://twitter.com/AzurLane_EN",
        src: "/assets/gallery-brian-29.webp",
        url: "https://www.youtube.com/watch?v=w_PufAqJIQU",
        tier: 5,
      },
      {
        name: "Taro Alt Version",
        artist: { name: "Yueko", url: "https://twitter.com/yueko__" },
        site: "https://twitter.com/VTTaro",
        src: "/assets/gallery-brian-30.webp",
        url: "https://www.youtube.com/watch?v=r9Gl_XrC42Q",
        tier: 3,
      },
      {
        name: "Taro",
        artist: { name: "Yueko", url: "https://twitter.com/yueko__" },
        site: "https://twitter.com/VTTaro",
        src: "/assets/gallery-brian-31.webp",
        url: "https://www.youtube.com/watch?v=uiLPzRp0wa8",
        tier: 3,
      },
      {
        name: "Ayunda Risu 2.0",
        artist: { name: "Yatomi", url: "https://twitter.com/8103x" },
        site: "https://www.youtube.com/channel/UCOyYb1c43VlX9rc_lT6NKQw",
        src: "/assets/gallery-brian-32.webp",
        url: "https://www.youtube.com/watch?v=n6jVwijD2ik",
        tier: 5,
      },
      {
        name: "Ayunda Risu 2.0 Outfit Change",
        artist: { name: "Yatomi", url: "https://twitter.com/8103x" },
        site: "https://www.youtube.com/channel/UCOyYb1c43VlX9rc_lT6NKQw",
        src: "/assets/gallery-brian-33.webp",
        url: "https://www.youtube.com/watch?v=hpD0A6MgVd0",
        tier: 5,
      },
      {
        name: "Ahniki 1.0",
        artist: { name: "Ahniki", url: "https://twitter.com/itsahniki" },
        site: "https://www.twitch.tv/ahniki",
        src: "/assets/gallery-brian-34.webp",
        url: "https://www.youtube.com/watch?v=cBnIZEF0ohM",
        tier: 3,
      },
      {
        name: "Bao 1.0",
        artist: { name: "Hayamafair", url: "https://twitter.com/hayamafair" },
        site: "https://www.twitch.tv/baoo",
        src: "/assets/gallery-brian-35.webp",
        url: "https://www.youtube.com/watch?v=QOhmuaCl0fE",
        tier: 3,
      },
      {
        name: "Bao 2.0",
        artist: { name: "Hayamafair", url: "https://twitter.com/hayamafair" },
        site: "https://www.twitch.tv/baoo",
        src: "/assets/gallery-brian-36.webp",
        url: "https://www.youtube.com/watch?v=YkE1ffW1XdA",
        tier: 3,
      },
      {
        name: "Bao 3.0",
        artist: { name: "Hayamafair", url: "https://twitter.com/hayamafair" },
        site: "https://www.twitch.tv/baoo",
        src: "/assets/gallery-brian-37.webp",
        url: "https://www.youtube.com/watch?v=rZLSvIslZ8s",
        tier: 3,
      },
      {
        name: "Gawr Gura Party Outfit",
        artist: { name: "Nachoneko", url: "https://twitter.com/amsrntk3" },
        site: "https://twitter.com/gawrgura",
        src: "/assets/gallery-brian-38.webp",
        url: "https://www.youtube.com/watch?v=aqXRPJ1b5os",
        tier: 5,
      },
      {
        name: "Gawr Gura Kimono Outfit",
        artist: { name: "Nachoneko", url: "https://twitter.com/amsrntk3" },
        site: "https://twitter.com/gawrgura",
        src: "/assets/gallery-brian-39.webp",
        url: "https://www.youtube.com/watch?v=AFBGROUD9ag",
        tier: 5,
      },
      {
        name: "Ninomae Ina'nis Party Outfit",
        artist: { name: "Kuroboshi", url: "https://twitter.com/kuroboshi" },
        site: "https://www.youtube.com/channel/UCMwGHR0BTZuLsmjY_NT5Pwg",
        src: "/assets/gallery-brian-40.webp",
        url: "https://www.youtube.com/watch?v=RJpZ8R8vjI4",
        tier: 5,
      },
      {
        name: "Ahniki 2.0",
        artist: { name: "Ahniki", url: "https://twitter.com/itsahniki" },
        site: "https://www.twitch.tv/ahniki",
        src: "/assets/gallery-brian-41.webp",
        url: "https://www.youtube.com/watch?v=swu5HNrIujQ",
        tier: 3,
      },
      {
        name: "Vienna 2.0",
        artist: { name: "HayamaFair", url: "https://twitter.com/hayamafair" },
        site: "twitter.com/viennavtuber",
        src: "/assets/gallery-brian-42.webp",
        url: "https://www.youtube.com/watch?v=QLJRTo8IjPw",
        tier: 3,
      },
    ],
  },
 enma: {
    id: 2,
    displayName: "Enma Akatsuki",
    name: "enma",
    rank: "IV Blacksmith",
    description: `
        Enma is an artist who started on Live2D in attempt to add movements to her drawings.
        After many experiements, Enma started to make more random things. One of her more prominent works was to rig not a person, but an entire room.
        This creation ultimately won her the Live2D Creative Award in 2018.    
        `,
    imageType: "webp",
    socialMedia: {
      twitter: "https://twitter.com/AkatsukiEnma",
      youtube: "https://www.youtube.com/channel/UCxFAEkWAWdCde1FYQzBVaKA",
      homepage: "https://www.artstation.com/enma_a/profile",
    },
    worksTitle: ["Commercial Works", "Tutorials", "Awards"],
    works: [
      [
        {
          title: "2020: Azur Lane Operation Sirens MV - \"Signal\" : Character Rigging ",
          url:
            "https://twitter.com/AzurLane_EN/status/1341338988917837826",
        },
      ],     
      [
        {
          title: "2019: Live2D 4.0 Official - How to Draw for Live2D",
          url:
            "https://www.youtube.com/playlist?list=PL3sGye8NKCQ-DHy01xNkRLSKlzZ5VPL6z",
        },
      ],
      [
        {
          title: "2018: Live2D Creative Award Grand Prize",
          url: "https://www.youtube.com/watch?v=4GJKYrKTVjk",
        },
      ],
    ],
    clients: [
      // {
      //     name: 'Live2D Creative Award 2018',
      //     src: '/assets/gallery-enma-00.webp',
      //     artist: { name: 'Enma', url: 'https://twitter.com/AkatsukiEnma' },
      //     url: 'https://www.youtube.com/watch?v=4GJKYrKTVjk',
      // },
      {
        name: "Shuimo Tofu",
        artist: { name: "Enma", url: "https://twitter.com/AkatsukiEnma" },
        url: "https://www.bilibili.com/video/av60884309",
        src: "/assets/gallery-enma-01.webp",
        site: "https://space.bilibili.com/7858535",
        tier: 4,
      },
      {
        name: "Yiji YY",
        artist: { name: "Suteinu A", url: "https://twitter.com/SuteinuA" },
        url: "https://www.youtube.com/watch?v=J7lMoSaEn6w",
        src: "/assets/gallery-enma-02.webp",
        tier: 3,
        site: "https://space.bilibili.com/147471",
      },
      {
        name: "Final Fantasy 14 China Server Producer",
        artist: { name: "Enma", url: "https://twitter.com/AkatsukiEnma" },
        src: "/assets/gallery-enma-03.webp",
        url: "https://space.bilibili.com/48648/",
        tier: 2,
      },
      {
        name: "Harune",
        artist: { name: "Nagu", url: "https://twitter.com/wooperfuri" },
        site: "https://haruneapp.com",
        src: "/assets/gallery-enma-04.webp",
        url: "https://www.youtube.com/watch?v=Aor4mWdAPMc",
        tier: 4,
      },
      {
        name: "Harutori",
        artist: { name: "Kokuri", url: "https://twitter.com/kokuri89490047" },
        src: "/assets/gallery-enma-05.webp",
        url: "https://www.youtube.com/watch?v=r4fUhgrWoSM",
        tier: 3,
      },
      {
        name: "Minami Nonno",
        artist: {
          name: "浅井猴猴_Saru",
          url: "https://space.bilibili.com/2848073/",
        },
        src: "/assets/gallery-enma-06.webp",
        site: "https://space.bilibili.com/511289062",
        url: "https://www.youtube.com/watch?v=1KZ6BmY14xQ",
        tier: 4,
      },
      {
        name: "Ace",
        artist: { name: "Enma", url: "https://twitter.com/AkatsukiEnma" },
        src: "/assets/gallery-enma-07.webp",
        site: "https://www.twitch.tv/acefortunech",
        url: "https://www.youtube.com/watch?v=rH92foPV_ag",
        tier: 2,
      },
      {
        name: "Part-Timer Rikku",
        artist: { name: "Enma", url: "https://twitter.com/AkatsukiEnma" },
        src: "/assets/gallery-enma-08.webp",
        site: "https://twitter.com/PartTimerRikku",
        url: "https://www.youtube.com/watch?v=z9H1N-XJBhw",
        tier: 2,
      },
      {
        name: "Makoto",
        artist: { name: "Ika", url: "https://bcy.net/u/101565685684" },
        src: "/assets/gallery-enma-09.webp",
        site: "https://twitter.com/MakotoYukiko_",
        url: "https://www.youtube.com/watch?v=bNg0n8hYZfw",
        tier: 3,
      },
      {
        name: "Azur Lane [Official] - Azur Lane Operation Siren MV (2020)",
        artist: { name: "Doomfest", url: "https://twitter.com/Doomfest" },
        site: "https://twitter.com/AzurLane_EN",
        src: "/assets/gallery-enma-10.webp",
        url: "https://www.youtube.com/watch?v=wao7kUZoP9w",
      },
      {
        name: "Moofie",
        artist: { name: "Ria", url: "https://twitter.com/tokkiria" },
        src: "/assets/gallery-enma-11.webp",
        site: "https://twitter.com/ItsMoofie",
        url: "https://www.youtube.com/watch?v=HAFb08sj6pc",
        tier: 3,
      },
      {
        name: "Snuffy",
        artist: { name: "Mazamuno", url: "https://twitter.com/mazamuno" },
        src: "/assets/gallery-enma-12.webp",
        site: "https://twitter.com/snuffyowo",
        url: "https://www.youtube.com/watch?v=dQw3kG0iCYw",
        tier: 2,
      },
      {
        name: "Hajime Hime",
        artist: { name: "Froot", url: "https://twitter.com/LichVtuber" },
        src: "/assets/gallery-enma-13.webp",
        site: "https://twitter.com/HimeHajime_VSJ",
        url: "https://www.youtube.com/watch?v=Ydqlki_Yrh8",
        tier: 3,
      },
      {
        name: "Project Kavvaii",
        artist: { name: "Yueko", url: "https://twitter.com/yueko__" },
        site: "https://twitter.com/AozoraKurumi",
        src: "/assets/gallery-soy-04.webp",
        url: "https://www.youtube.com/watch?v=iXP5nIVR9uI",
        tier: 2,
      },
      {
        name: "Omi",
        artist: { name: "Kamo", url: "https://twitter.com/kamochiruu" },
        src: "/assets/gallery-enma-14.webp",
        site: "https://twitter.com/omi_vt",
        url: "https://www.youtube.com/watch?v=BqWedDJ_0uQ",
        tier: 3,
      },
      {
        name: "Fen",
        artist: { name: "Moofie", url: "https://twitter.com/itsmoofie" },
        src: "/assets/gallery-enma-15.webp",
        site: "https://twitter.com/fen_vt",
        url: "https://www.youtube.com/watch?v=P8UrjkfDjqU",
        tier: 3,
      },
      {
        name: "Kitty",
        artist: { name: "Moofie", url: "https://twitter.com/itsmoofie" },
        src: "/assets/gallery-enma-16.webp",
        site: "https://twitter.com/Kitty_Mama_TV",
        url: "https://www.youtube.com/watch?v=BUQyDYCsbao",
        tier: 2,
      },
    ],
  },
  zensei: {
    id: 3,
    displayName: "Zensei",
    name: "zensei",
    rank: "IV Blacksmith",
    imageType: "webp",
    description: `
         Zensei started as a programmer wanting to make his own game for a living. 
         Things didn't work out so Zensei decided to train the creative side of his brain, and went into digital arts. 
         It was a struggle to make a living out of it, and Zensei was slowly giving up.
         About 4 years ago, Zensei learnt about Live2D and started playing with it.
         A few months later - plus a chain of events, it ended up as his career/business which helped Zensei reach some of his goals in a short span of time. `,
    socialMedia: {
      twitter: "https://twitter.com/ZenseiKun",
      youtube: "youtube.com/channel/UCwuRxeSikJ7KP3ClgF_bP1w",
    },
    worksTitle: ["Fan Art"],
    works: [
      [
        {
          title: "Fan Art: Tobiichi Origami",
          url: "https://twitter.com/ZenseiKun/status/1119353713254998016",
        },
      ],
    ],
    clients: [
      // {
      //     name: '',
      //     artist: 'Koshi Tachibana, Tsunako',
      //     url: 'https://twitter.com/ZenseiKun/status/1119353713254998016',
      //     src: '/assets/gallery-zensei-00.webp',

      // },
      {
        name: "Path of The Midnight Sun",
        artist: { name: "Nene", url: "https://twitter.com/nenekantoku" },
        src: "/assets/gallery-zensei-01.webp",
        url: "https://www.youtube.com/watch?v=9gSwiXWSumA",

        site:
          "https://www.kickstarter.com/projects/studiodaimon/path-of-the-midnight-sun-a-dark-fantasy-jrpg-vn?ref=thanks-tweet",
        tier: 4,
      },
      {
        name: "Nyanners",
        artist: { name: "Nia", url: "https://space.bilibili.com/11041707" },
        src: "/assets/gallery-zensei-02.webp",
        url: "https://www.youtube.com/watch?v=F-9hxFLCh0o",
        site: "https://twitter.com/NyanNyanners",
        tier: 2,
      },
      {
        name: "Akuma Yuu",
        artist: { name: "ETAun", url: "https://twitter.com/un_eta" },
        src: "/assets/gallery-zensei-03.webp",
        url: "",
        site: "https://www.bilibili.com/video/BV1Va411y7KH",
        tier: 3,
      },
      {
        name: "Kaia",
        artist: { name: "Kaia", url: "https://twitter.com/kaiatato" },
        src: "/assets/gallery-zensei-04.webp",
        site: "https://www.twitch.tv/kaiapi ",
        url: "https://www.youtube.com/watch?v=LXIMEk4tsbQ",
        tier: 3,
      },
      {
        name: "Pahi",
        artist: { name: "Yokura", url: "https://twitter.com/yokudraws" },
        src: "/assets/gallery-zensei-05.webp",
        site: "https://twitter.com/PahiChannel",
        url: "https://www.youtube.com/watch?v=kZUcmLPoUlg",
        tier: 3,
      },
      {
        name: "Fuyumi",
        artist: { name: "Satchel", url: "https://twitter.com/_Satchely" },
        src: "/assets/gallery-zensei-06.webp",
        site: "https://twitter.com/TobaFuyumi",
        url: "https://www.youtube.com/watch?v=oW0DogAHVV0",
        tier: 3,
      },
      {
        name: "BV Hammer",
        artist: { name: "Runsa", url: "https://twitter.com/runsachan" },
        src: "/assets/gallery-zensei-07.webp",
        site: "https://twitter.com/BVStrix1",
        url: "https://www.youtube.com/watch?v=MXBOe4sP_Rg",
        tier: 3,
      },
      {
        name: "Zentreya",
        artist: { name: "Yuniiho", url: "https://twitter.com/yuniiho" },
        src: "/assets/gallery-zensei-08.webp",
        site: "https://twitter.com/zentreya",
        url: "https://www.youtube.com/watch?v=Wyso1HpwASI",
        tier: 3,
      },
      {
        name: "Cienna",
        artist: { name: "Nana Kagura", url: "https://twitter.com/nana_kaguraaa" },
        src: "/assets/gallery-zensei-09.webp",
        site: "https://twitter.com/MemoriCiena",
        url: "https://www.youtube.com/watch?v=mxy6OSYh6IM",
        tier: 3,
      },
    ],
  },
  cetra: {
    id: 4,
    displayName: "Cetra",
    name: "cetra",
    imageType: "webp",
    rank: "IV Blacksmith",
    description: `Cetra discovered Live2D from a game called Destiny Child. The game sparked her interested to learn 
        about more about Live2D. At first, it started just as a hobby - testing out how to add movement to her drawings, etc.
        From there on, Live2D is slowly becoming a focus for her career.
        `,
    socialMedia: {
      twitter: "https://twitter.com/etctr_",
    },
    worksTitle: ["Commercial Works"],
    works: [  
       [ 
        {
          title: "2021: Azur Lane April Fools Login Screen : Character Rigging ",
          url:
            "https://twitter.com/AzurLane_EN/status/1377651974401839109",
        },
        {
          title: "2020: Azur Lane Operation Sirens MV - \"Signal\" : Character Rigging ",
          url:
            "https://twitter.com/AzurLane_EN/status/1341338988917837826",
        },    ]
    ],
    clients: [
      {
        name: "Sakura Saya",
        artist: {
          name: "Nana Kagura",
          url: "https://twitter.com/nana_kaguraaa",
        },
        site: "https://space.bilibili.com/196558078",
        src: "/assets/gallery-yami-00.webp",
        url: "https://www.youtube.com/watch?v=lWQFyMJYhJQ",
        tier: 3,
      },
      {
        name: "Kurenai Akane",
        artist: {
          name: "Nana Kagura",
          url: "https://twitter.com/nana_kaguraaa",
        },
        site: "https://space.bilibili.com/899804",
        src: "/assets/gallery-yami-01.webp",
        url: "https://www.youtube.com/watch?v=GsO9YDaCPD0",
        tier: 3,
      },
      {
        name: "OGTexas",
        artist: { name: "StarPyrate", url: "https://twitter.com/starpyrate" },
        site: "https://twitter.com/TheOGTexas",
        src: "/assets/gallery-yami-02.webp",
        url: "https://www.youtube.com/watch?v=Zxtce_b4qss",
        tier: 1,
      },
      {
        name: "Paige",
        artist: { name: "Yami", url: "https://twitter.com/etctr_" },
        site: "https://twitter.com/Lucky_BunTTV",
        src: "/assets/gallery-yami-03.webp",
        url: "https://www.youtube.com/watch?v=lmHo4jEvXzU",
        tier: 2,
      },
      {
        name: "Jade",
        artist: { name: "Yami", url: "https://twitter.com/etctr_" },
        site: "https://twitter.com/Jade_The_Bug",
        src: "/assets/gallery-yami-04.webp",
        url: "https://www.youtube.com/watch?v=VaWi_ksQIfE",
        tier: 2,
      },
      {
        name: "XinYue Bing Bing",
        artist: { name: "Nerv110", url: "https://www.pixiv.net/users/323383" },
        site: "https://space.bilibili.com/3295",
        src: "/assets/gallery-yami-06.webp",
        url: "https://www.youtube.com/watch?v=R_PlJaWGWAs",
        tier: 2,
      },
      {
        name: "Azur Lane [Official] - Azur Lane Operation Siren MV (2020)",
        artist: { name: "Doomfest", url: "https://twitter.com/Doomfest" },
        site: "https://twitter.com/AzurLane_EN",
        src: "/assets/gallery-yami-07.webp",
        url: "https://www.youtube.com/watch?v=wao7kUZoP9w",
      },
      {
        name: "Azur Lane [Official] - Azur Lane April Fools Login (2021)",
        artist: { name: "JanyHero", url: "https://twitter.com/Janyhero" },
        site: "https://twitter.com/AzurLane_EN",
        src: "/assets/gallery-yami-08.webp",
        url: "https://www.youtube.com/watch?v=w_PufAqJIQU",
      },
      {
        name: "Mochie",
        artist: { name: "Yampa", url: "https://twitter.com/yampaham" },
        site: "https://twitter.com/itskumamochie",
        src: "/assets/gallery-yami-09.webp",
        url: "https://www.youtube.com/watch?v=DEnmzfxPvXI",
        tier: 3,
      },
      {
        name: "Elita",
        artist: { name: "Nia", url: "https://twitter.com/Nia_0703" },
        site: "https://twitter.com/ConductorElita",
        src: "/assets/gallery-yami-10.webp",
        url: "https://www.youtube.com/watch?v=i4DpmS6Tqz8",
        tier: 3,
      },
      {
        name: "Jaime (IGumDrop)",
        artist: { name: "LucidSky", url: "https://twitter.com/lu_cid_sky" },
        site: "https://twitter.com/iGumdrop",
        src: "/assets/gallery-yami-11.webp",
        url: "https://www.youtube.com/watch?v=FWd4IScwvio",
        tier: 3,
      },
      {
        name: "Wurmz 2.0",
        artist: { name: "WaifuWight", url: "https://twitter.com/WaifuWight" },
        site: "https://www.twitch.tv/wurmzzzz",
        src: "/assets/gallery-yami-12.webp",
        url: "https://www.youtube.com/watch?v=bpbjQl62K14",
        tier: 2,
      },
      {
        name: "AnnTan",
        artist: { name: "Ann", url: "https://twitter.com/AnntanDesu" },
        site: "https://www.twitch.tv/anntan",
        src: "/assets/gallery-yami-13.webp",
        url: "https://www.youtube.com/watch?v=QrX4B4wHPnE",
        tier: 3,
      },
      {
        name: "Ironmouse Ver. 8",
        artist: { name: "Hen", url: "https://twitter.com/myonehen" },
        site: "https://twitter.com/ironmouse",
        src: "/assets/gallery-yami-15.webp",
        url: "https://www.youtube.com/watch?v=r4qbr_bGNVg",
        tier: 3,
      },
      {
        name: "Usagi",
        artist: { name: "Cetra", url: "https://twitter.com/etctr_" },
        site: "https://twitter.com/RoyalGeekz",
        src: "/assets/gallery-yami-14.webp",
        url: "https://www.youtube.com/watch?v=nHirJTwqIHs",
        tier: 2
      },
    ],
  },
  katloumi: {
    id: 5,
    imageType: "webp",
    displayName: "Katloumi",
    name: "katloumi",
    rank: "IV Blacksmith",
    description: `Katloumi first solely focused towards being a professional artist,
    but having interest with Vtubers Katloumi was curious as to how they operate.
    After learning about Live2D from a friend Katloumi decided to invest the time to learn the program out,
    in which time Katloumi found the Live2D Community.
    Inspired by the community Katloumi quickly took interest and decided to focus on learning more about Live2D as a career.
    `,
    socialMedia: {
      twitter: "https://twitter.com/katloumi",
    },
    worksTitle: ["Commercial Works"],
    works: [
      [
        {
          title: "2021: Azur Lane April Fools Login Screen : Character Rigging ",
          url:
            "https://twitter.com/AzurLane_EN/status/1377651974401839109",
        },
        {
          title: "2020: Azur Lane Operation Sirens MV - \"Signal\" : PSD Preparation Support ",
          url:
            "https://twitter.com/AzurLane_EN/status/1341338988917837826",
        },    ]
    ],
    clients: [
      {
        name: "Wight",
        artist: {
          name: "Waifu Wight",
          url: "https://twitter.com/WaifuWight",
        },
        site: "https://twitter.com/WaifuWight",
        src: "/assets/gallery-katloumi-01.webp",
        url: 'https://www.youtube.com/watch?v=6tENQACO4dY',
        tier: 2,
      },
      {
        name: "Azur Lane [Official] - Azur Lane April Fools Login (2021)",
        artist: { name: "JanyHero", url: "https://twitter.com/Janyhero" },
        site: "https://twitter.com/AzurLane_EN",
        src: "/assets/gallery-katloumi-02.webp",
        url: "https://www.youtube.com/watch?v=w_PufAqJIQU",
      },
      {
        name: "Wight 2.0",
        artist: {
          name: "Waifu Wight",
          url: "https://twitter.com/WaifuWight",
        },
        site: "https://twitter.com/WaifuWight",
        src: "/assets/gallery-katloumi-03.webp",
        url: "https://www.youtube.com/watch?v=lRdmXV1Y0XE",
        tier: 2,
      },
      {
        name: "Ashelia",
        artist: {
          name: "Unamiya",
          url: "https://twitter.com/xxx06",
        },
        site: "https://www.twitch.tv/asheliabun",
        src: "/assets/gallery-katloumi-04.webp",
        url: "https://www.youtube.com/watch?v=60AsrF3wpE4",
        tier: 2,
      },
      {
        name: "Pearl",
        artist: {
          name: "Eta_un",
          url: "https://twitter.com/un_eta",
        },
        site: "https://twitter.com/PearlOtter",
        src: "/assets/gallery-katloumi-05.webp",
        url: "https://www.youtube.com/watch?v=fG9suf0d_Ts",
        tier: 3,
      },
      {
        name: "Cassiopeia",
        artist: {
          name: "Lacha",
          url: "https://twitter.com/azolitmin",
        },
        site: "https://www.twitch.tv/cassioppe_luna",
        src: "/assets/gallery-katloumi-06.webp",
        url: "https://www.youtube.com/watch?v=19bVWIINhg4",
        tier: 3,
      },
      {
        name: "Yuki Usada",
        artist: {
          name: "Akainu",
          url: "https://twitter.com/fenlil0316",
        },
        site: "https://twitter.com/yukiusada",
        src: "/assets/gallery-katloumi-07.webp",
        url: "https://www.youtube.com/watch?v=rpRu0Z0i_K8",
        tier: 3,
      },
    ],
  },
  soy: {
    id: 6,
    imageType: "webp",
    displayName: "Soy",
    name: "soy",
    rank: "Project Manager",
    description: `Soy started working behind the scenes for Iron Vertex in early 2019 as the team's project manager. 
    Her main work involved liasing between clients, artists and riggers to ensure a project efficient production. In late 2019, Soy began to take on Live2D rigging work to gain a better technical understanding of Live2D, 
    with the main goal to faciliate better communication between clients and riggers. As the demand increased, Soy also joined the rigging team and has now completed multiple Live2D works on her own.
    `,
    socialMedia: {
      twitter: "https://twitter.com/sei_soy",
      youtube: "https://www.youtube.com/channel/UCRQ2QuRIEOuNUFKLSW8g1_A",
    },
    worksTitle: ["Commercial Works"],
    works: [
      [
        {
          title: "2020: Azur Lane Operation Sirens MV - \"Signal\" : PSD Preparation Support  & Character Rigging",
          url:
            "https://twitter.com/AzurLane_EN/status/1341338988917837826",
        },    ]
    ],
    clients: [
      {
        name: "Nyanners 2.0",
        artist: {
          name: "Nia",
          url: "https://twitter.com/Nia_0703",
        },
        site: "https://twitter.com/NyanNyanners",
        src: "/assets/gallery-soy-01.webp",
        url: 'https://www.youtube.com/watch?v=d-iNCPTEEyA',
        tier: 2,
      },
      {
        name: "Suteinu A",
        artist: { name: "Suteinu A", url: "https://twitter.com/suteinuA" },
        site: "https://twitter.com/suteinuA",
        src: "/assets/gallery-soy-02.webp",
        url: "https://www.youtube.com/watch?v=bzwsxMe0LjA",
        tier: 3,
      },
      {
        name: "Azur Lane [Official] - Azur Lane April Fools Login (2021)",
        artist: { name: "JanyHero", url: "https://twitter.com/Janyhero" },
        site: "https://twitter.com/AzurLane_EN",
        src: "/assets/gallery-katloumi-02.webp",
        url: "https://www.youtube.com/watch?v=w_PufAqJIQU",
      },
      {
        name: "Haruka Karibu",
        artist: { name: "Rimuu", url: "https://twitter.com/rimuuarts" },
        site: "https://twitter.com/haruka_karibu",
        src: "/assets/gallery-soy-03.webp",
        url: "https://www.youtube.com/watch?v=VFcOAGsLrQI",
        tier: 3,
      },
      {
        name: "Project Kavvaii",
        artist: { name: "Yueko", url: "https://twitter.com/yueko__" },
        site: "https://twitter.com/AozoraKurumi",
        src: "/assets/gallery-soy-04.webp",
        url: "https://www.youtube.com/watch?v=iXP5nIVR9uI",
      },
      {
        name: "Lilypichu (Chibi)",
        artist: { name: "Kirss Sison", url: "https://twitter.com/KrissSison" },
        site: "https://twitter.com/LilyPichu",
        src: "/assets/gallery-soy-05.webp",
        url: "https://www.youtube.com/watch?v=G3qITtEcTVU",
      },
    ],
  },
};

export const sortByTier = (function () {
  let byTier = [0, [], [], [], [], []];
  Object.keys(memberInformation).forEach((m) => {
    //push the item to each corresponding array.
    memberInformation[m].clients.forEach((c, idx) => {
      c.tier && byTier[c.tier].push({ ...c, animator: m, index: idx });
    });
  });
  return byTier;
})();

export default memberInformation;
