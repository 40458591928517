const instructorInformation = {
  denchi: {
    id: 0,
    displayName: "Denchi",
    name: "denchi",
    rank: "IV Instructor",
    description: `Denchi is a software engineer and hobby artist who, after seeing Brian's videos on YouTube, decided to pick up Live2D to build games with cute girls in them in his free time. Years later, Denchi specializes in using the Live2D SDK in interactive and creative ways, always trying to push the limits of what's possible with the technology. In 2019, he won the Live2D Creative Award in the "Interactive" category.`,
    socialMedia: {
      youtube: "https://www.youtube.com/DenchiSoft",
      twitter: "https://twitter.com/DenchiSoft",
    },
    worksTitle: ["Awards", "SDK Works"],
    works: [
      [
        {
          title: "2019: Live2D Creative : Interactive Category",
          url: "https://www.live2d.jp/en/award2019_finalist/",
        },
      ],
      [
        {
          title: "VTube Studio",
          url: "https://twitter.com/VTubeStudio",
        },
        {
          title: "DenchiSoft: Live2D related programmatic projects",
          url: "https://github.com/DenchiSoft",
        },
      ],
    ],
    portfolio: [
      {
        name: "VTube Studio",
        url: "https://denchi.itch.io/vtube-studio",
        artist: { name: "Denchi", url: "" },
        src: "/assets/instructor-gallery-denchi-00.webp",
        feature: [
          `A project that Denchi has been working on. VTube Studio is an open source Virtual Youtuber System that combines Google's powerful ARCore augmented reality toolkit for face-tracking with Live2D and Unity`,
          `Supports unlimited hot keys`,
          `Customizable background, scenes, lightning effects`,
          `..and much more.`,
        ],
      },
      {
        name: "Fan Art: Senko-san",
        url: "https://www.youtube.com/watch?v=Yj6OkmbFB2A",
        artist: { name: "Denchi", url: "" },
        src: "/assets/instructor-gallery-denchi-01.webp",
        feature: [
          `A project that showcases Live2D models' intergration into 3D environments`,
        ],
      },
      {
        name: "Fan Art: Platelet-chan",
        artist: { name: "Denchi", url: "" },
        url: "https://www.youtube.com/watch?v=VklgMAUWzxg",
        src: "/assets/instructor-gallery-denchi-02.webp",
        feature: [
          `A Live2D desktop wallpaper`,
          `Right-click on her to show controls & configs`,
          `Model can sit on user's windows`,
          `Looks cute`,
          `Can be lip-sync to microphone input`,
        ],
      },
      {
        name: "Original Visual Novel Engine",
        artist: { name: "Denchi", url: "" },
        url: "https://www.youtube.com/watch?v=QUXYwJ6zNa8",
        src: "/assets/instructor-gallery-denchi-03.webp",
        feature: [
          `A visual novel engine developed by Denchi`,
          `Supports Live2D SDK`,
        ],
      },
    ],
  },

  yamakaze: {
    id: 1,
    displayName: "Yamakaze",
    name: "yamakaze",
    rank: "IV Instructor",
    description: `
            Yamakaze is most known for his work 
            in the mobile game "Azur Lane". A professional animator who current works for a major 
            China-based game company, Yamakaze is also a Live2D consultant for multiple projects that are still under development. 
        `,
    socialMedia: {
      bilibili: "https://space.bilibili.com/670575",
      twitter: "https://twitter.com/tobu616",
      homepage: "https://weibo.com/u/6501055298/home",
    },
    worksTitle: ["Showcase"],
    works: [
      [
        {
          title: "Mobile Game: Azur Lane: Ibuki",
          url: "https://www.bilibili.com/video/BV15b411r71u",
        },
        {
          title: "Mobile Game: Azur Lane: Zara",
          url: "https://www.bilibili.com/video/BV1FE411d7ch",
        },
      ],
    ],
  },

  yanyu: {
    id: 1,
    displayName: "Yanyu",
    name: "yanyu",
    rank: "IV Instructor",
    imageType: "webp",
    description: `
        Yanyu was once an architect in the East. In his free time, he manifested reading and drawing. His curiosity in animation led him into the 2D world. He describes himself as an animator with a slight hint of insanity... an animator with insatiable desire to learn more.
        `,
    socialMedia: {
      bilibili: "https://space.bilibili.com/7468371",
      twitter: "https://twitter.com/yanyu_Live2D",
      youtube: "https://www.youtube.com/channel/UCLxTPsTtpDw24U8dYHnhj7g",
    },
    worksTitle: ["Game Projects"],
    works: [
      [
        {
          title: "Mobile Game: Depose Girls",
          url: "https://twitter.com/houchishoujo/status/1277255435498614785",
        },
        {
          title: "Mobile Game: Onmyji: The Card Game",
          url: "https://www.youtube.com/watch?v=NNlLdy8EdFo",
        },
        {
          title: "Mobile Game: Girls Guns Cafe",
          url: "https://www.youtube.com/watch?v=tyObuNB6sHg",
        },
        {
          title: "Mobile Game: Food Language",
          url: "https://www.youtube.com/watch?v=Ne4oFtjqjFs",
        },
        {
          title: "Mobile Game: Horcrux College: Sophia",
          url: "https://www.bilibili.com/video/av70839117",
        },
      ],
    ],
    portfolio: [
      {
        name: "Chóng Guāng Yè Xī",
        url: "https://www.bilibili.com/video/av93037946",
        artist: { name: "", url: "" },
        src: "/assets/instructor-gallery-yanyu-00.webp",
        feature: [`A custom-made Vup project created for Chóng Guāng Yè Xī`],
      },
      {
        name: "Yandere",
        url: "https://www.bilibili.com/video/av93037946",
        artist: {
          name: "LUNCH",
          url: "https://www.pixiv.net/en/users/4282910",
        },
        src: "/assets/instructor-gallery-yanyu-01.webp",
        feature: [`A hobby project created by Yanyu.`],
      },
      {
        name: "Flowers & Butterflies",
        url: "https://www.bilibili.com/video/av64676111",
        artist: { name: "Mia", url: "https://www.pixiv.net/en/users/1014053" },
        src: "/assets/instructor-gallery-yanyu-02.webp",
        feature: [`A hobby project created by Yanyu.`],
      },
      {
        name: "Ark Knight: CliffHeart [Fan Art]",
        url: "https://www.bilibili.com/video/av57653897",
        artist: { name: "Mia", url: "https://www.pixiv.net/en/users/1014053" },
        src: "/assets/instructor-gallery-yanyu-03.webp",
        feature: [`A hobby project created by Yanyu.`],
      },
    ],
  },

  qquack: {
    id: 1,
    displayName: "QQuack",
    name: "qquack",
    rank: "IV Instructor",
    description: `
            QQuack started working as an animator since 2013. 
            He has worked with several game projects, most noticibly Girls Frontline.
             Quoting from him directly, "I like to make cute characters move. And I get paid for it. Life is good."
        `,
    socialMedia: {
      twitter: "https://twitter.com/KCAUQQ",
      youtube:
        "https://www.youtube.com/channel/UCNFBbYeox-J3YbnwUTniqrg?view_as=subscriber",
    },
    worksTitle: ["Game Projects"],
    works: [
      [
        {
          title: "Mobile Game: Girls Frontline: HK416",
          url: "https://www.youtube.com/watch?v=ShwscDl2yHM",
        },
        {
          title: "Mobile Game: Girls Frontline: KP-31 Midnight Summer",
          url: "https://www.youtube.com/watch?v=BDIng73Yj0U",
        },
        {
          title: "Mobile Game: Girls Frontline: Five-SeveN",
          url: "https://www.youtube.com/watch?v=OM69w_tEpLw&t=20s",
        },
      ],
    ],
    clients: [
      {
        name: "Nabi",
        artist: {
          name: "Nabi",
          url: "https://twitter.com/aoinabi",
        },
        site: "https://www.youtube.com/c/Nabii",
        src: "/assets/gallery-qquack-01.webp",
        url: 'https://www.youtube.com/watch?v=QwGBMOSm4gc',
        tier: 3,
      },
      {
        name: "Cherrypach",
        artist: { name: "유콘/𝙐𝙆𝙊𝙉", url: "https://twitter.com/ukon60" },
        site: "https://twitter.com/AzurLane_EN",
        src: "/assets/gallery-qquack-03.webp",
        url: "https://www.youtube.com/watch?v=WW6S5e_h658",
        tier: 3,
      },
      {
        name: "Huz 2.0",
        artist: { name: "PoPuru", url: "https://twitter.com/Dr_poapo" },
        site: "https://www.twitch.tv/queenhuz",
        src: "/assets/gallery-qquack-04.webp",
        url: "https://www.youtube.com/watch?v=EI2SR1-NAdg",
        tier: 3,
      },
    ],
  },
};

export default instructorInformation;
