import styled from "styled-components";
import hexToRgb from "../../utils/helpers";

const Background = styled.div`

    //Note: for "double" backgorunds, the last one in the line stands on top layer
    background-image: 
        ${(props) =>
          props.gradientString
            ? props.gradientString
            : `linear-gradient(to right bottom, rgba(${hexToRgb(
                props.theme.main
              )},0.92) 40%,  rgba(${hexToRgb(
                props.theme.sub_dark
              )},0.8) 99%) `}, 
        ${(props) =>
          props.backgroundImg1x
            ? `url(${props.backgroundImg1x})`
            : `url('${window.location.origin}/assets/header-bg-2.webp')`};
    background-size: contain;
    background-position: top; 
    background-attachment: fixed;
    background-repeat-x: no-repeat;
`;

export default Background;
