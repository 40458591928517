import React from 'react';
import TierSamples from '../../Compositions/TierSamples';
import Tutorials from '../../Compositions/Tutorials';
import Header from '../../Compositions/Header';
import Companies from '../../Compositions/Companies';

const MainPage = () => (
    <React.Fragment>
        <Header />
        <TierSamples />
        <Companies />
        <Tutorials />
    </React.Fragment>
)

export default MainPage;
