import React from "react";
import styled from "styled-components";

import { IV_EMAIL, TWITTER_URL } from "../../utils/Links";
import Background from "../../Components/Background";

import ReturnButton from "../../Compositions/ReturnButton";
import HeaderText from "../../Components/HeaderText";
import { Link } from "react-router-dom";

const ContactContainer = ({ children }) => (
  <Background
    backgroundImg1x={"/assets/background-iv-text-1x.webp"}
    backgroundImg2x={"/assets/background-iv-text-2x.webp"}
    backgroundImg3x={"/assets/background-iv-text-3x.webp"}
  >
    {children}
  </Background>
);

const Description = styled.div`
  width: 70vw;
  max-width: 800px;
  padding: 2rem;
  margin: 0 auto;
  background-image: linear-gradient(
    to bottom,
    rgba(188, 198, 204, 0.3),
    rgba(238, 238, 238, 0.3),
    rgba(188, 198, 204, 0.2)
  );
  font-size: 2rem;
  color: white;
  height: 50vh;
`;
const Space = styled.div`
  height: 5rem;
`;

const LinkSocial = styled(Link)`
  font-weight: bold;
  :link,
  :active,
  :visited {
    text-decoration: none;
    color: white;
  }
  :hover {
    text-shadow: 3px 3px 0.5rem rgba(254, 254, 254, 0.7);
  }
`;

const Contact = () => (
  <ContactContainer>
    <HeaderText title={"Contact us"} />
    <Description>
      For VTuber works inquiries, we are currently closed for commissions. As we are a small team, all resources will be focusing on existing clients that we have already accepted. Responses to emails and general inquiries may not be immediate.
      For upcoming announcements, please check out our Twitter account: <LinkSocial as="a" href={TWITTER_URL}>{TWITTER_URL}</LinkSocial>.
      <Space/>
      For all other business inquiries, please e-mail us at {IV_EMAIL}
    </Description>
    <ReturnButton route={"/"} />
  </ContactContainer>
);

export default Contact;
