const alumniInformation = {
  synckage: {
    id: 0,
    displayName: "Sync Kage",
    name: "synckage",
    rank: "IV Alumni",
    imageType: "webp",
    description: `Sync loves games and anime. After betting several years on being as comic artist, Sync ends up becoming a Live2D animator. 
        Sync decided to shift focus to Live2D since 2018. She keeps her comic dream alive and well with creating small merchandises base on her comic ideas. 
        Her favourite quote - "You know, with animation you can create you own world!"`,
    socialMedia: {
      twitter: "https://twitter.com/synckage1",
      artstation: "https://www.artstation.com/synckage",
    },
    worksTitle: ["Commercial Works"],
    works: [
       [ {
          title: "2020: Azur Lane Operation Sirens MV - \"Signal\" : Character Rigging ",
          url:
            "https://twitter.com/AzurLane_EN/status/1341338988917837826",
        },    ]
    ],
    clients: [
      {
        name: "Path of The Midnight Sun",
        artist: { name: "Nene", url: "https://twitter.com/nenekantoku" },
        url: "https://www.youtube.com/watch?v=9gSwiXWSumA",
        site:
          "https://www.kickstarter.com/projects/studiodaimon/path-of-the-midnight-sun-a-dark-fantasy-jrpg-vn?ref=thanks-tweet",
        img: "gallery-synckage-00.webp",
        src: "/assets/gallery-synckage-00.webp",
      },
      {
        name: "Hyurno",
        artist: { name: "Gianny Suki", url: "https://twitter.com/giannysuki" },
        site: "https://t.co/5GVuoJbYjt?amp=1",
        url: "https://www.youtube.com/watch?v=d5rSVTL2Tac",
        src: "/assets/gallery-synckage-01.webp",
        img: "gallery-synckage-01.webp",
        tier: 2,
      },
      {
        name: "Azur Lane [Official] - Azur Lane Operation Siren MV (2020)",
        artist: { name: "Doomfest", url: "https://twitter.com/Doomfest" },
        site: "https://twitter.com/AzurLane_EN",
        src: "/assets/gallery-synckage-02.webp",
        url: "https://www.youtube.com/watch?v=wao7kUZoP9w",
      },
      {
        name: "Project Kavvaii",
        artist: { name: "Yueko", url: "https://twitter.com/yueko__" },
        site: "https://twitter.com/AozoraKurumi",
        src: "/assets/gallery-soy-04.webp",
        url: "https://www.youtube.com/watch?v=iXP5nIVR9uI",
      },
    ],
  },
};

export default alumniInformation;
