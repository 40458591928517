import React from "react";
import styled, { ThemeContext } from "styled-components";

import { TitleWideText as Title } from "../../Components/Title";
import { ButtonWhite, ButtonReturn } from "../../Components/Buttons";
import Card, { CardFrontBlock, CardBackBlock } from "../../Components/Card";

import { Link } from "react-router-dom";

const SectionTiers = styled.section`
  text-align: center;
`;
const Header = styled(Title)`
  padding: 1rem;
  margin-bottom: 2rem;
  height: 4rem;
  color: ${(props) => props.theme.font_dark};
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  margin-bottom: 2rem;

  @media screen and (max-width: 935px) {
    max-height: 70%;
    flex-direction: column;
  }
`;

const CardContainer = styled.div`
  height: 42rem;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 2rem;
`;

const CTA = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
`;

const Ul = styled.ul`
  list-style: none;
  width: 80%;
  margin: 0 auto;
`;

const Li = styled.li`
  font-size: 1.5rem;
  font-weight: 200;

  padding: 1rem;
  &:not(:last-child) {
    border-bottom: 1px solid white;
  }

  @media (max-width: 650px) {
    font-size: 2.2rem;
  }
`;

const tierDetails = [
  {},
  {},
  {
    id: 3,
    listItems: [
      "Upper-Body Movement",
      "One Accessories",
      "Two Add-On Stickers",
    ],
  },
  {
    id: 4,
    listItems: [
      "Full Body Movement",
      "Second Set of Arms",
      "Four Add-On Stickers",
    ],
  },
  {
    id: 5,
    listItems: [
      "Enterprise Game Sprite",
      "7+ Expressions",
      "Virtual Novel or RPG Usage",
    ],
  },
];

const CTABlock = ({ time, tag }) => (
  <CTA>
    <ButtonWhite to="" as={"a"} href={`/tier${tag}`}>
      View Demos
    </ButtonWhite>
  </CTA>
);

const T4CardFront = () => {
  const theme = React.useContext(ThemeContext);
  return (
    <CardFrontBlock
      mainColor={theme.main}
      gradient2={theme.sub}
      bgImage={`${window.location.origin}/assets/cards-mia-01.webp`}
      header={"T4"}
      clipPath={"polygon(0 0, 100% 0, 100% 80%, 40% 100%, 0 86%)"}
    >
      <Ul>
        {tierDetails[3].listItems.map((item, index) => (
          <Li key={index}>{item}</Li>
        ))}
      </Ul>
    </CardFrontBlock>
  );
};

const Tier4CardBack = () => {
  const theme = React.useContext(ThemeContext);

  return (
    <CardBackBlock gradient1={theme.main} gradient2={theme.highlight}>
      <CTABlock as={"a"} time={14} tag={"#tier4"} />
    </CardBackBlock>
  );
};

const T3CardFront = () => {
  const theme = React.useContext(ThemeContext);

  return (
    <CardFrontBlock
      mainColor={theme.sub}
      gradient2={theme.main}
      bgImage={`${window.location.origin}/assets/cards-chai-01.webp`}
      header={"T3"}
      clipPath={"polygon(0 0, 100% 0, 100% 80%, 0 40%)"}
    >
      <Ul>
        {tierDetails[2].listItems.map((item, index) => (
          <Li key={index}>{item}</Li>
        ))}
      </Ul>
    </CardFrontBlock>
  );
};

const Tier3CardBack = () => {
  const theme = React.useContext(ThemeContext);
  return (
    <CardBackBlock gradient1={theme.sub} gradient2={theme.highlight}>
      <CTABlock as={"a"} time={10} tag={"#tier3"} />
    </CardBackBlock>
  );
};

const T5CardFront = () => {
  const theme = React.useContext(ThemeContext);
  return (
    <CardFrontBlock
      mainColor={theme.sub_bright}
      gradient2={theme.sub}
      bgImage={`${window.location.origin}/assets/cards-yuna-01.webp`}
      header={"T5"}
      clipPath={"polygon(0 0, 100% 0, 100% 40%, 0 75%)"}
    >
      <Ul>
        {tierDetails[4].listItems.map((item, index) => (
          <Li key={index}>{item}</Li>
        ))}
      </Ul>
    </CardFrontBlock>
  );
};

const Tier5CardBack = () => {
  const theme = React.useContext(ThemeContext);
  return (
    <CardBackBlock gradient1={theme.sub_bright} gradient2={theme.highlight}>
      <CTABlock as={"a"} time={15} tag={"#tier5"} />
    </CardBackBlock>
  );
};

const TierSamples = () => (
  <SectionTiers>
    <Header>Our Works</Header>
    <FlexContainer>
      <CardContainer>
        <Card front={T3CardFront} back={Tier3CardBack} />
      </CardContainer>
      <CardContainer>
        <Card front={T4CardFront} back={Tier4CardBack} />
      </CardContainer>
      <CardContainer>
        <Card front={T5CardFront} back={Tier5CardBack} />
      </CardContainer>
    </FlexContainer>
    <ButtonReturn as={Link} route="/tier" text="View Works" />
  </SectionTiers>
);

export default TierSamples;
