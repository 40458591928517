import styled from "styled-components";
import React from "react";
import { ReactComponent as HomePageIcon } from "../homepage.svg";
import SVGLink from "../Base/";

const HomePageIconStyled = styled(HomePageIcon)`
  height: 5rem;
  width: 6rem;
  stroke: white;
  stroke-width: 1;
  fill: white;
  padding-bottom: 1rem;

  :hover {
    cursor: pointer;
    transform: scale(1.05);
    transition: fill 0.2s cubic-bezier(0.19, 1, 0.22, 1);
  }
`;

const HomePageIconLink = ({ url }) => (
  <SVGLink href={url} target="_blank">
    <HomePageIconStyled />
  </SVGLink>
);

export default HomePageIconLink;
