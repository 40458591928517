import React from "react";
import styled from "styled-components";

export const ContentHeader = styled.div`
  margin-top: 2em;
  padding: 0.4em;
  border: 1px solid ${(props) => props.theme.font_light};
  border-left: none;
  border-bottom: none;
  color: ${(props) => props.theme.font_light};
  text-align: right;
  font-size: 4em;
`;

export const ContentWrapper = styled.div`
  padding-bottom: 2em;
  border: 1px solid ${(props) => props.theme.font_light};
  border-right: none;
  border-top: none;
`;

const Section = ({ title, children }) => (
  <>
    <ContentHeader>{title}</ContentHeader>
    <ContentWrapper>{children}</ContentWrapper>
  </>
);

export default Section;
