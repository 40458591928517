import React from "react";
import styled, { ThemeContext } from "styled-components";

import TextBaseBgContainer from "../../Compositions/TextBaseBgContainer";
import AboutCard from "../../Compositions/AboutCard";
import hexToRgb from "../../utils/helpers";

const AboutContainer = styled(TextBaseBgContainer)`
  padding-bottom: 10em;
`;

const Space = styled.span`
  display: block;
  height: 3em;
`;

const AboutParent = ({ member, children }) => {
  const theme = React.useContext(ThemeContext);

  const gradient1 = hexToRgb(theme.highlight);
  const gradient2 = hexToRgb(theme.shadow);

  return (
    <AboutContainer
      gradientString={`linear-gradient(to bottom, rgba(${gradient1}, 0.7) 80%, rgba(${gradient2}, 0.2) 100%)`}
    >
      <Space />
      <AboutCard {...member} theme={theme}>
        {children}
      </AboutCard>
    </AboutContainer>
  );
};

export default AboutParent;
