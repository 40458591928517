const Tier = [
    0,
    [
        'Head Tilts',
        'Upper Body Tilts',
        'Full Body Tilts',
        '1 Set of Hotkey Toggled Expressions'
    ],
    [
        'Head Tilts',
        'Head Turn (Left/Right)',
        'Upper Body Side Tilt',
        '1 Set of Hotkey Toggled Expression & Animations'
    ],
    [
        'Head Tilts',
        'Head Turn (Left/Right)',
        'Upper Body Turn (Left/Right)',
        'Full Body Tilts',
        '1 Set of Hotkey Toggled Expressions & Animations'
    ],
    [
        'Head Tilts',
        'Head Turn (Left/Right)',
        'Full Body Turn (Left/Right)',
        'Full Body Lean Forward',
        '1 Set of Hotkey Toggled Expressions & Animations'
    ],
    [
        'Customize Rigging for Game Asset / Music Video Usage',
        'For more information, please contact inquiries@ironvertex.com'
    ],
]

export default Tier;
