import styled, { ThemeContext } from "styled-components";
import hexToRgb from "../../utils/helpers";
import React from "react";

export const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  grid-gap: 3rem;
  max-width: 70vw;
  margin: 0 auto;
  justify-content: center;
`;

const GridCardStyled = styled.div`
  width: 100%;

  border-radius: 5px;
  margin: 2rem 0;
  box-shadow: -3px -3px 0.1rem rgba(${(props) => hexToRgb(props.theme.main)}, 0.3);

  padding-bottom: 70px;

  :hover {
    box-shadow: 3px 3px 1rem rgba(0, 0, 0, 0.7);
    transform: scale(1.05);
    transition: all 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  position: relative;
`;

const GridCardImage = styled.img`
  padding: 4rem 2rem 0 2rem;
  max-width: 100%;
  object-fit: cover;
`;

const GridCardTextBlock = styled.div`
  padding: 2rem;
  font-size: 1.4rem;
  color: white;
`;

const CardTitle = styled.div`
  font-size: 1.8rem;
  letter-spacing: 2px;
  text-align: center;
  color: white;
  text-shadow: 3px 3px 1rem rgba(254, 254, 254, 0.7);
`;

const GridCard = ({ src, title, summary, children }) => (
  <GridCardStyled>
    <GridCardImage src={src} alt="gridcard" />
    <GridCardTextBlock>
      <CardTitle>{title}</CardTitle>
      {summary}
    </GridCardTextBlock>
    {children}
  </GridCardStyled>
);

const FlexCard = styled(GridCardStyled)`
  display: flex;
  flex-wrap: wrap;
  max-width: 800px;
  margin: 2rem auto;

  :hover {
    transform: scale(1.05);
    transition: all 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
`;
const FlexCardImage = styled(GridCardImage)`
  width: 50%;
  max-width: 400px;
  margin: 0 auto;
  flex-grow: 1;
`;

const FlexCardTextBlock = styled(GridCardTextBlock)`
  width: 300px;
  flex-grow: 1;
`;

const CardText = styled.span`
  padding-top: 1rem;
  @media (max-width: 500px) {
    display: none;
    padding-top: 0;
  }
`;

export const FlexCardHorizontal = ({ src, title, summary, children }) => {
  const theme = React.useContext(ThemeContext);
  return (
    <FlexCard theme={theme}>
      <FlexCardImage src={src} alt="flexCard" />
      <FlexCardTextBlock>
        <CardTitle>{title}</CardTitle>
        <CardText>{summary}</CardText>
      </FlexCardTextBlock>
      {children}
    </FlexCard>
  );
};

export default GridCard;
