export const DARKPURPLE = "#534961";
export const DARKRED = "#74454B";
export const DARKYELLOW = "#DAA138";
export const DARKBROWN = "#3d2e33";

export const ENGLISH_VIOLET = "#534961";
export const SILVER_SAND = "#C1CCD2";
export const IVORY = "#FEFEF4";
export const TEAL_BLUE = "#0C7489";
export const CG_BLUE = "#187795";

export const CHARCOAL = "#3C4B53";
export const PERSIAN_GREEN = "#1EA896";
export const CAROLINA_BLUE = "#00A8E8";

export const ORANGE_SODA = "#F55D3E";

export const VERMILLION = "#e3170a";
export const BLUE_BELL = "#9395D3";
/**
 * Dark Brown: 74454B
 * Dark Red: C4403B
 * Dark yellow: DAA138
 * Dark PUrple: 534961;  #3d2e33  rgba(83, 73, 97, 1)
 */
