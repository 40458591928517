import styled, { ThemeContext } from "styled-components";
import React from "react";
import { Link } from "react-router-dom";

import { DARKRED } from "../../utils/color";
import { moveInBottom, shine } from "../../Animations/keyframes";

const Button = styled(Link)`
  :link,
  :visited {
    //state of button selector, special condition
    text-transform: uppercase;
    text-decoration: none;
    padding: 1.5rem 4rem;
    //is inline-element, bc just text so:
    display: inline-block;
    border-radius: 10rem;

    font-size: 16px;

    transition: all 0.2s; //animate all

    position: relative; //for other buttons to anchor to.
  }

  &::after {
    content: ""; //have to specify to appear
    display: inline-block; //like button;
    height: 100%;
    width: 100%;
    border-radius: 10rem;

    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    //initiatl state for hover:after:
    transition: all 0.4s;
  }

  &:hover {
    transform: translateY(-0.3rem); //Y axis move from top to bottom in html
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2); //none on x, 10px on y 20px blur and subtly black

    &::after {
      transform: scaleX(1.4) scaleY(1.6);
      opacity: 0;
    }
  }

  :active {
    transform: translateY(-1rem); // in relative to link state.
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
  }
`;

export const ButtonWhite = styled(Button)`
  animation: ${moveInBottom} 0.5s ease-out 0.75s; //delay .75 seconds
  animation-fill-mode: backwards; //auto apply 0% styles before animation start!
  background-color: white;
  color: #777;

  :after {
    background-color: white;
  }
`;

const ButtonReturnBase = styled(Button)`
  animation-fill-mode: backwards; //auto apply 0% styles before animation start!
  background-color: ${(props) => props.theme.sub};
  color: white;

  :after {
    background-color: ${(props) => props.theme.sub};
  }

  @media (max-width: 935px) {
    width: 80vw;
    border-radius: 2rem !important;
  }
`;

export const ButtonReturn = ({ route, text }) => {
  const theme = React.useContext(ThemeContext);
  return (
    <ButtonReturnBase to={route} theme={theme}>
      {text}
    </ButtonReturnBase>
  );
};

export const SmallButton = styled(Button)`
  :link,
  :visited {
    //state of button selector, special condition
    text-transform: uppercase;
    text-decoration: none;

    //is inline-element, bc just text so:
    display: inline-block;

    //font-size: 1rem;

    transition: all 0.2s; //animate all

    position: relative; //for other buttons to anchor to.
  }

  animation: ${moveInBottom} 0.5s ease-out 0.9s; //delay .75 seconds
  animation-fill-mode: backwards; //auto apply 0% styles before animation start!
  background-color: ${DARKRED};
  color: white;

  :after {
    background-color: ${DARKRED};
  }
`;

const ButtonMetallicStyled = styled.div`
  font-size: 2rem;
  padding: 2rem 2rem;
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  background: linear-gradient(${(props) => props.gradientString});
`;

const ButtonMetallicWrapper = styled.div`
  position: relative;
  min-width: 168px;
  overflow: hidden;

  clip-path: polygon(0 0, 100% 0, 70% 100%, 0 100%);
  backface-visibility: hidden;

  :hover {
    transform: scale(1.05);
    transition: transform 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
    box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.7);

    &:after {
      content: "";
      top: 0;
      transform: translateX(100%) rotate(45deg);

      width: 100%;
      height: 300%;
      position: absolute;
      z-index: 1;
      animation: ${shine} 3s cubic-bezier(0.075, 0.82, 0.165, 1);
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.8) 25%,
        rgba(128, 186, 232, 0) 99%,
        rgba(128, 186, 232, 0) 100%
      );
    }
  }
`;

export const ButtonMetallic = ({ gradientString, children }) => (
  <ButtonMetallicWrapper>
    <ButtonMetallicStyled gradientString={gradientString}>
      {children}
    </ButtonMetallicStyled>
  </ButtonMetallicWrapper>
);
