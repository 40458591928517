import React from "react";
import styled from "styled-components";
import ReturnButton from "../../Compositions/ReturnButton";
import { animated } from "react-spring";
import Background from "../../Components/Background";
import HeaderText from "../../Components/HeaderText";
import CollapsibleContainer from "../../Components/Collapsible";
import { faqInfo } from "../../utils/FAQInfo/";

const FaqContainer = ({ children }) => (
  <Background
    backgroundImg1x={"/assets/background-iv-text-1x.webp"}
    backgroundImg2x={"/assets/background-iv-text-2x.webp"}
    backgroundImg3x={"/assets/background-iv-text-3x.webp"}
  >
    {children}
  </Background>
);

const Description = styled.div`
  width: calc(90% - 1vw);
  max-width: 800px;
  padding: 2rem;
  margin: 0 auto;
  background-image: linear-gradient(
    to bottom,
    rgba(188, 198, 204, 0.3),
    rgba(238, 238, 238, 0.3),
    rgba(188, 198, 204, 0.2)
  );
  font-size: 2rem;
  color: white;
  min-height: 25vh;
`;

const Space = styled.div`
  height: 5rem;
`;

const FAQContentHeader = styled(animated.div)`
  :hover {
    cursor: pointer;
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  }
  background-repeat: no-repeat;
  :hover {
    cursor: pointer;
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  }

  background-image: linear-gradient(
    to bottom,
    rgba(188, 198, 204, 0.3),
    rgba(238, 238, 238, 0.3),
    rgba(188, 198, 204, 0.2)
  );
  width: 100%;
  padding: 10px 20px;

  text-align: left;
  font-size: 2rem;
  color: white;
`;

const DescriptionContainer = styled.div`
  margin: 0 auto;
  width: calc(90% - 1vw);
  flex-grow: 1;
  max-width: 800px;
  padding: 2rem 0;
`;

const FAQAnswer = styled(DescriptionContainer)`
  margin-top: 1rem;
  width: 100%;
  border-left: 1px solid white;
  border-bottom: 1px solid white;
  font-size: 2rem;
  color: white;
  padding: 2rem;
`;

const FAQPoints = styled.span`
  margin-bottom: 7rem;
`;

const FAQImg = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const FAQImgContainer = styled.div`
  min-height: 40vh;
`;

const FAQLinkText = styled.span``;

const FAQLink = styled.a`
  padding-left: 5px;
  :link,
  :active,
  :visited {
    color: white;
  }
`;

const Faq = () => (
  <FaqContainer>
    <HeaderText title={"FAQ"} />
    <Description>
      The following FAQ is applicable to prospective VTuber (Virtual YouTuber) clients. If you are looking for game assets, please contact us
      directly.
      <Space />
      Please note, the answers to these questions may not fully apply to your
      circumstances. If you have any questions, it is best to contact IV
      directly via inquiries@ironvertex.com
    </Description>
    <DescriptionContainer>
      {faqInfo.map(({ question, answer }, index) => (
        <CollapsibleContainer
          index={index}
          key={`${question}${index}`}
          header={FAQContentHeader}
          headerText={`${index + 1}. ${question}`}
          textalign={"left"}
          fontsize={"2rem"}
        >
          <FAQAnswer>
            {answer &&
              answer.map((point, index) => {
                return point.text ? (
                  <>
                    <FAQPoints>{point.text}</FAQPoints>
                    <Space />
                  </>
                ) : point.linkText ? (
                  <>
                    <FAQLinkText>{point.linkText}</FAQLinkText>
                    <FAQLink href={point.link} target="_blank">
                      {point.linkActive}
                    </FAQLink>
                  </>
                ) : (
                  <FAQImgContainer>
                    <FAQImg src={point.img} />
                  </FAQImgContainer>
                );
              })}
          </FAQAnswer>
        </CollapsibleContainer>
      ))}
    </DescriptionContainer>
    <ReturnButton route="/" />
  </FaqContainer>
);

export default Faq;
