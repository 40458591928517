import React from "react";
import styled, { ThemeContext } from "styled-components";

import Background from "../../Components/Background";
import { ButtonMetallic } from "../../Components/Buttons";
import GridCard, { GridWrapper } from "../../Components/GridCards";
import ReturnButton from "../../Compositions/ReturnButton";
import tutorialInfo from "../../utils/TutorialInfo/index";
import HeaderText from "../../Components/HeaderText";

const ButtonMetallicPosition = styled.div`
  position: absolute;
  bottom: 1rem;
`;

const TutorialContainer = styled(Background)`
  background-attachment: fixed;
  padding-bottom: 7rem;
`;

const Tutorial = () => {
  const theme = React.useContext(ThemeContext);

  return (
    <TutorialContainer>
      <HeaderText
        title={"IV Tutorials"}
        sub={`Live2D tutorials created by Brian and fellow IV members. Tutorials are endorsed by Live2D Inc.
                Please do not redistribute or resell the videos without permission.`}
      />
      <GridWrapper>
        {tutorialInfo.map((info) => (
          <GridCard
            key={info.id}
            src={`${window.location.origin}/assets/tutorial-${info.instructor}-${info.id}.webp`}
            title={info.title}
            summary={info.summary}
          >
            <ButtonMetallicPosition
              as="a"
              href={info.link}
              style={{ textDecoration: "none" }}
            >
              <ButtonMetallic
                gradientString={` to bottom, ${theme.sub_bright}, ${theme.sub_bright}`}
              >
                View
              </ButtonMetallic>
            </ButtonMetallicPosition>
          </GridCard>
        ))}
      </GridWrapper>
      <ReturnButton route={"/"} />
    </TutorialContainer>
  );
};

export default Tutorial;