import React from "react";
import styled from "styled-components";
import { TitleWideText as Title } from "../../Components/Title";

const LOGOS = [
  ['CrunchyRoll', 'Crunchyroll', 'https://twitter.com/Crunchyroll/status/1448031506757144578'],
  ['OmegaStrikersLogo', 'Omega Strikers', 'https://twitter.com/PlayOmega/status/1581384057833000961'],
  ['Netflix', 'Netflix', 'https://www.netflix.com/'],
  ['omocat_logo', 'Omocat', 'https://twitter.com/Katloumi/status/1667277701961613312'],
  ['HyperX', 'HyperX', 'https://twitter.com/HyperX/status/1620165014832259077'],
  ['AirAsia', 'AirAsia', 'https://newsroom.airasia.com/news/airasia-to-debut-first-virtual-idol'],
  ['HololiveProduction', 'Hololive Production', 'https://www.youtube.com/watch?v=r8eTPf3Ywb8&list=PLdjfmvWCNnl8C976F1Sa1ZxYFHrUNoFlq'],
  ['VShojoLogo', 'VShojo', 'https://www.youtube.com/watch?v=2zVVsLdhD00&list=PLdjfmvWCNnl-IL7F5CsQlgQS5N6A6gVI0'],
  ['VStream', 'VStream', 'https://twitter.com/vstream_ada'],
  ['ApexLegends', 'Apex Legends', 'https://twitter.com/iron_vertex/status/1756127898673774936'],  
  ['Animax', 'Animax', 'https://www.youtube.com/playlist?list=PLdjfmvWCNnl_PHiNAu0asJ74jJ9423yO7'],
  ['Yostar-Black', 'Yostar', 'https://twitter.com/AzurLane_EN/status/1562832178450513920'],
  ['Cubism-logo-orange', 'Live2D Cubism', 'https://www.youtube.com/watch?v=yiIRGzTthII&list=PL3sGye8NKCQ-DHy01xNkRLSKlzZ5VPL6z'],
  ['MakeAWish_Black', 'Make-A-Wish Foundation', 'https://twitter.com/iron_vertex/status/1515774902313361423'],
  ['TwitchExtrudedWordmarkPurple', 'Twitch', 'https://www.twitch.tv/'],
  ['RiotGames', 'Riot Games', 'https://twitter.com/BrianTsuii/status/1721622672755294309'],
  ['TFT', 'Teamfight Tactics', 'https://twitter.com/BrianTsuii/status/1721622672755294309'],
];

const Header = styled(Title)`
  margin-top: 4rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  line-height: 3rem;
`;

const TutorialHeader = styled(Header)`
  padding-top: 0;
  color: ${(props) => props.theme.font_dark};
`;

const CompaniesSection = styled.div`
  margin-top: 10rem;
  margin-bottom: 8rem;
`;

const Logos = styled.div`
  max-width: 160rem;
  width: 100%;
  margin: 1rem auto 3rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: flex-start;
`;

const Company = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 2rem;

  @media (max-width: 500px) {
    width: 100%;
  }
`;

const Logo = styled.img`
  height: 9rem;
`;

const Companies = () => {
  return (
    <CompaniesSection>
      <TutorialHeader>Our Clients</TutorialHeader>
      <Logos>
        {LOGOS.map(([name, alt, site]) => {
          const src = `/assets/logos/${name}.webp`
          return (
            <Company
              key={src}
              target="_blank"
              rel="noopener noreferrer"
              href={site}
              title={alt}
            >
              <Logo src={src} alt={alt} />
            </Company>
          );
        })}
      </Logos>
    </CompaniesSection>
  );
}

export default Companies;
