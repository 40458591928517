import Modal from 'react-modal';
import React from 'react';


const modalStyles = {
    overlay: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: '10000'
    },
    content: {
        position: 'static',
        display: 'inline-block',
        padding: 'none',
        border: 'none',
        background: 'none',
        overflow: 'hidden',
        width: '80%',
        // boxShadow: '0 1rem 2rem rgba(0,0,0, .2);'

    }
};


const PopupModal = ({ modalOpen, setModalOpen, children }) => {
    Modal.setAppElement("#root")
    return (
        <Modal
            isOpen={modalOpen}
            onRequestClose={() => setModalOpen(false)}
            style={modalStyles}
            closeTimeoutMS={400}
        >
            {children}
        </Modal>
    )
}

export default PopupModal;