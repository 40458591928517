export const faqInfo = [
  {
    question: "How do I start?",
    answer: [
      {
        text:
          "You need an illustration in PSD format. Afterwards please get in contact with IV so we can generate a quote and set up cutting instructions.",
      },
      {
        text:
          "Once the cutting is completed, we can then start on your project.",
      },
      {
        text:
          "If you do not have an illustration, IV can provide a one-stop service to complete the entire product development cycle. IV is in contact with a number of artists that are experienced in drawing for Live2D, which reduces the supervision cost and communication errors while providing a much smoother commission experience.",
      },
    ],
  },
  {
    question: "What does cutting instruction mean?",
    answer: [
      {
        text:
          "The PSD layers need to follow Live2D logic, which usually is not intuitive to artists. For this reason, we provide a set of cutting instructions tailored for your illustration.",
      },
    ],
  },
  {
    question:
      "Why do you need my artist to follow cutting instructions so meticulously?",
    answer: [
      {
        text:
          "We give cutting instructions based on our professional experience in rigging for Live2D. ",
      },
      {
        text:
          "For example, if a part's 'void' (part that is hidden behind the art but will become visible during movement) is not filled, your model may expose hollowed area between parts. Or if a part is not cut out properly, your model in motion may look unnatural or flat. Or, we would not be able to make sophisticated physics movements if parts are bundled together. Sometimes there is pixel-dirt in the layers, which may put a smudge on your model’s face.",
      },
      {
        text:
          " We care about the model’s quality as much as you do. For that reason, we require the PSD to be prepared before we can start.",
      },
    ],
  },
  {
    question: "Why do you need a full PSD before you can give a quote?",
    answer: [
      {
        text: `The pose of your character affects the complexity of the model. We need to know the complexity to estimate the workload, and then quote a price.`,
      },
      {
        text: `Other than the pose, the arrangement of the PSD layers is another factor. 
        The PSD layers need to follow Live2D logic, which usually is not intuitive to artists. An unoptimized PSD constraints our end to bring out the full potential out of the art material. We are experienced in assisting artists of various art styles to learn how to process the PSD into “Live2D-ready” by providing tailor-made cutting instructions. The surcharge for this service varies case-by-case. Alternatively, we also have experienced artists in our network for PSD processing commission.
        `,
      },
    ],
  },
  {
    question: "How are Iron Vertex's fees calculated ?",
    answer: [
      {
        text: `
            PSD complexity, Brian's supervision, quality assurance, communication on requirements & PSD preparation, and translation cost are all factored into total fee.
        `,
      },
      {
        text: `This is the reason why no two projects would have the same cost. Without your PSD file and your requirements, we are unable to provide a quote.`,
      },
    ],
  },
  {
    question: "What is the difference between tilting and turning?",
    answer: [{ img: "/assets/TiltingTurning.webp" }],
  },
  {
    question: "What are accessories?",
    answer: [
      {
        text:
          "Accessories are any items that do not naturally belong to a humanoid body. Some examples would include: glasses, animal ears, necklaces, belts, ribbons, etc.",
      },
    ],
  },
  {
    question: "What are stickers?",
    answer: [
      {
        text:
          "Stickers are small decors used to enhance the model’s expression. They are similar to Manpu.",
      },
      {
        linkText: "If you'd like see some examples, see",
        linkActive: "here",
        link: "https://www.irasutoya.com/2015/06/blog-post_275.html",
      },
    ],
  },
  {
    question: "What’s the difference between texture swaps and stickers?",
    answer: [
      {
        text:
          "Texture swaps are applied to pieces that already exist. An example would be swapping between a normal smiley mouth to a cat mouth. Stickers, on the other hand, are usually either hidden / displayed, and are not being swapped out to replace another texture piece.",
      },
    ],
  },
  {
    question:
      "What face-tracking softwares does Iron Vertex support right now?",
    answer: [
      {
        text:
          "Iron Vertex is focusing resources on developing models for VTube Studio. We currently do not support any other face-tracking softwares.",
      },
    ],
  },
  {
    question:
      "My model was previously rigged by a non-Iron Vertex animator. Can you upgrade / re-rig my model?",
    answer: [
      {
        text: "As of September 2020, we do not accept such projects.",
      },
      {
        text:
          "The reason for this policy is because Iron Vertex has encountered issues before where multiple animators re-rigged the same illustration. Unlike artists, it is tough for Live2D animators to be properly credited.",
      },
      {
        text:
          "This led to a situation, where it was difficult for the Iron Vertex animator to be credited properly, and it was also difficult for the original animator to be creditted properly. It became awkward for multiple parties involved. To avoid these situations, Iron Vertex will only take on projects that starts fresh. We apologize if this causes any inconvenience.",
      },
    ],
  },
];
