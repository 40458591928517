const TutorialInfo = [
  {
    id: "bt-001",
    title: "Introduction to Technical Concepts and Tools",
    summary:
      "This tutorial series is a professional Live2D training program with 19 episodes, around 10 minutes each. Build a skillset that the industry needs you for. Build your model like every vertex is made of iron.",
    link:
      "https://www.youtube.com/playlist?list=PLhha8OGApHGMkC4F41zN3fFaSBRu-YIrc",
    instructor: "brian",
  },
  {
    id: "ea-001",
    title: "Drawing for Live2D: Standard Components",
    summary:
      "Suitable for both artists and Live2D users to understand a standard Live2D-ready art material (PSD). A well-prepared material can maximize your model's feature potentials and movement range.",
    link:
      "https://www.youtube.com/playlist?list=PL3sGye8NKCQ-DHy01xNkRLSKlzZ5VPL6z",
    instructor: "enma",
  },
  {
    id: "az-001",
    title: "How to make a 2D Model [Chinese]",
    summary:
      "Learn the good practices of industrial standard through making a 2-D model. The finished model can be used in other tutorials - using Live2D in Visual Novel Maker, or expanding into a 3D model for FaceRig.",
    link:
      "https://www.youtube.com/playlist?list=PL7bpXtXNk19pyjx4PqFL0UQ0g0JIp48jE",
    instructor: "zelo",
  },
];

export default TutorialInfo;

/**
 * {
        id: 'zs-001',
        title: 'How to make Game Sprites with Live2D',
        summary: 'Let\'s make FGO-like game assets! One of the high-value Live2D usages is animated 2D game assets. Quick, light, scalable, and requires minimum illustrations.',
        link: 'https://www.youtube.com/playlist?list=PLhha8OGApHGMkC4F41zN3fFaSBRu-YIrc',
        instructor: 'zensei'
    },
 */
