import React from "react";

import { createGlobalStyle, ThemeProvider } from "styled-components";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";

import Footer from "./Compositions/Footer";
import Navigation from "./Compositions/Navigation";
import MainPage from "./Pages/MainPage";
import About from "./Pages/About";
import Tutorial from "./Pages/Tutorial";
import Tiers from "./Pages/Tiers";
import AboutMain from "./Pages/AboutMain";
import Contact from "./Pages/Contact";
import Faq from "./Pages/FAQ";

import {
  ENGLISH_VIOLET,
  SILVER_SAND,
  IVORY,
  CHARCOAL,
  ORANGE_SODA,
  BLUE_BELL,
} from "./utils/color.js";
const GlobalStyle = createGlobalStyle`

	@import url('https://fonts.googleapis.com/css?family=Raleway:200&display=swap');

	*,
	::after,
	::before {	//also psuedo elements, inherit. if not stated would not inherit
		margin: 0;
		padding: 0; //These two allow start clean;

		//box-sizing: border-box;
		box-sizing: inherit; //all should inherit box-sizing., is what it's saying.
	}

	html {
		//font-size: 10px; //bc this sets to "1 rem"
		//default is 16px, if set to 100%
		//note IE9 or less does not support rem
		font-size: 62.5%; //setting it to 10px, but via % so accessibility for bad eye sight will increase, no "fixed size"

		@media screen and (max-width: 600px) {
			font-size: 50%;
		}

		@media screen and (max-width: 450px) {
			font-size: 35%;
    }

    overflow: scroll;
    overflow-x: hidden;
  }
  
  ::-webkit-scrollbar {
    width: 0px;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
  }


	body {
		//following all inherited
		font-family: Raleway, comic sans;
		font-weight: 200;
		// font-size: 16px;
		line-height: 1.7; //1.7 bigger than predefined line-height.
		color: #777;

		padding: 3rem; /*//30px; //note, padding does not get inherited*/
		box-sizing: border-box;
   

		//For Modal:
		> .ReactModalPortal > .ReactModal__Overlay {
			margin: 0 auto;
			width: 85%;
			transition: all 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);

		}

		> .ReactModalPortal > .ReactModal__Overlay--after-open {
			width: 100%;
		}

		> .ReactModalPortal > .ReactModal__Overlay--before-close {
			width: 85%;
			opacity: 0;
		}
	}

`;

const ScrollToTop = () => {
  const { pathname } = useLocation();
  React.useEffect(() => {
    console.log("Path name: ", pathname);
    if (pathname !== "/tier") {
      window.scrollTo(0, 0);
    }
  }, [pathname]);
  return null;
};

const theme = {
  main: ENGLISH_VIOLET,
  sub: ORANGE_SODA,
  sub_dark: CHARCOAL,
  sub_bright: BLUE_BELL,
  highlight: IVORY,
  shadow: SILVER_SAND,
  font_light: IVORY,
  font_dark: ENGLISH_VIOLET,
  button_action: ORANGE_SODA,
  button_return: SILVER_SAND,
};

function App() {
  return (
    <Router>
      <ScrollToTop />
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <Navigation />
        <Switch>
          <Route exact path="/" render={() => <MainPage />} />
          <Route exact path="/faq" render={() => <Faq />} />
          <Route exact path="/tutorial" render={() => <Tutorial />} />
          <Route exact path="/contact" render={() => <Contact />} />
          <Route exact path="/tier" render={() => <Tiers />} />
          <Route exact path="/about" render={() => <AboutMain />} />
          <Route
            exact
            path="/about/:id"
            render={(props) => <About id={props.match.params.id} />}
          />
          <Route render={() => <MainPage />} />
        </Switch>
        <Footer />
      </ThemeProvider>
    </Router>
  );
}

export default App;
