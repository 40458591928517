import styled from "styled-components";
import React from "react";
import { ReactComponent as BilibiliIcon } from "../bilibili-logo.svg";
import SVGLink from "../Base/";

const BilibiliIconStyled = styled(BilibiliIcon)`
  margin-top: 2rem;
  height: 5rem;
  fill: white;

  :hover {
    cursor: pointer;
    transform: scale(1.05);
    transition: fill 0.2s cubic-bezier(0.19, 1, 0.22, 1);
  }
`;

const BilibiliIconLink = ({ url }) => (
  <SVGLink href={url} target="_blank">
    <BilibiliIconStyled />
  </SVGLink>
);

export default BilibiliIconLink;
