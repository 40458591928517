
import styled from 'styled-components';


export const TitleWideText = styled.h2`
    font-size: 3rem;
    font-weight: 200;
    line-height: 1.2rem;
    letter-spacing: 2rem;
    text-align: center;
    text-transform: uppercase;
`