import React from "react";
import styled from "styled-components";
import GalleryTier from "../ModalTier";
import GalleryPortfolio from "../ModalPortfolio";
import { useSpring, animated } from "react-spring";
import hexToRgb from "../../utils/helpers";

const GalleryWrap = styled.div`
  margin: 0 auto;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 20px;
  border: 1px solid ${(props) => props.theme.highlight};
  border-top: none;
  border-right: none;
`;

const GalleryItem = styled(animated.div)`
    margin: 0 auto;
    flex: 1 1 20%;
    height: 150px;
    margin: 0.5em;
    background-image:  url('${(props) => props.img}');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;

    @media screen and (max-width: 900px){
       flex: 1 1 50%;
    }

    :hover {
        box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3);
        transform: scale(1.02)
    }
    position:relative;
    overflow: hidden;
    cursor: pointer;
`;

const VeilSpan = styled(animated.span)`
  z-index: 2;
`;

const Veil = styled(animated.div)`
  position: absolute;
  right: -1;
  background-image: linear-gradient(
    to right,
    rgba(${(props) => hexToRgb(props.theme.main)}, 0.7) 40%,
    rgba(${(props) => hexToRgb(props.theme.sub_dark)}, 0.6)
  );
  width: 115%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  transform: translateY(0);
`;

const GalleryItemAnimated = ({ item, handleModalToggle }) => {
  const [{ x }, set] = useSpring(() => ({
    x: 0,
  }));

  return (
    <GalleryItem
      img={item.src}
      onClick={() => handleModalToggle(item)}
      onTouchEnd={() => set({ x: 100 })}
      onMouseEnter={() => set({ x: 100 })}
      onMouseLeave={() => set({ x: 0 })}
    >
      <VeilSpan />
      <Veil style={{ transform: x.interpolate((v) => `translateX(${v}%)`) }} />
    </GalleryItem>
  );
};

const Gallery = ({ gallery, animator, modaltype }) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [modalContent, setModalContent] = React.useState({});

  const handleModalToggle = (item) => {
    setModalOpen(true);
    setModalContent(item);
  };

  return (
    <GalleryWrap>
      {modaltype === "tier" ? (
        <GalleryTier
          setModalOpen={setModalOpen}
          modalOpen={modalOpen}
          item={modalContent}
        />
      ) : (
        <GalleryPortfolio
          setModalOpen={setModalOpen}
          modalOpen={modalOpen}
          item={modalContent}
        />
      )}
      {gallery &&
        gallery.map((item, index) => {
          animator && (item.animator = animator);
          return (
            <GalleryItemAnimated
              key={`G-${index}`}
              item={item}
              handleModalToggle={handleModalToggle}
            />
          );
        })}
    </GalleryWrap>
  );
};

export default Gallery ;