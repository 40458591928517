import styled from "styled-components";
import React from "react";

import { ButtonReturn } from "../../Components/Buttons";

const ButtonWrapper = styled.div`
  min-width: 300px;
  text-align: center;
  padding: 3rem 0;
`;

const ReturnButton = ({ route }) => (
  <ButtonWrapper>
    <ButtonReturn route={route} text="Return" />
  </ButtonWrapper>
);
export default ReturnButton;
