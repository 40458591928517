import React from "react";
import styled from "styled-components";

const CardBase = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  transition: all 1.1s;
  border-radius: 5px;
  overflow: hidden;
  //prevents bg in corner overflowing
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.4);
`;

const CardFront = styled(CardBase)``;

const CardFrontStyled = styled(CardFront)` 
    color: #fff;
    background-color ${(props) => props.bgColor};
    height: 100%;
    
`;
const CardBack = styled(CardBase)`
  transform: rotateY(180deg);
  backface-visibility: hidden;
  height: 100%;
`;

const CardBackStyled = styled(CardBack)`
  color: #fff;
  background-image: linear-gradient(
    to right bottom,
    ${(props) => props.gradient1 + "," + props.gradient2}
  );
`;

const CardImage = styled.div`
  height: 25rem;
  background-image: linear-gradient(
      to right bottom,
      ${(props) => props.gradient1 + "," + props.gradient2}
    ),
    url(${(props) => props.bgImage});
  background-size: cover;
  background-position: center;

  background-blend-mode: screen; /*safari, ff, chrome only...*/
  background-origin: content-box;
  clip-path: ${(props) => props.clipPath};
`;

const CardHeader = styled.h4`
  font-size: 3.8rem;
  font-weight: 200;
  text-transform: uppercase;
  color: ${(props) => props.textColor};

  text-align: right;
  position: absolute;
  top: 0;
  right: 0;

  background-color: rgb(256, 256, 256, 0.7);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 0);
  text-shadow: 2px 2px 5px rgb(197, 197, 197);
  width: 100%;
  height: 6rem;
  padding-right: 20px;
  padding-bottom: 10px;
  //box-decoration-sizing: clone;
`;

const CardDetail = styled.div`
  padding: 0 2rem;
`;

const CardContainer = styled.div`
    //perspective define in container:
    perspective: 150rem;
    position: relative;

    //note: posi absolute takes the items out of normal flow, parent height collapses.
    height: 100%;

    : hover > ${CardFront} {
    transform: rotateY(-180deg);
}

    : hover > ${CardBack} {
    transform: rotateY(0);
}
`;

export const CardFrontBlock = ({
  mainColor,
  gradient2,
  bgImage,
  clipPath,
  header,
  children,
}) => (
  <CardFrontStyled bgColor={mainColor}>
    <CardImage
      bgImage={bgImage}
      gradient1={mainColor}
      gradient2={gradient2}
      clipPath={clipPath}
    >
      &nbsp;
    </CardImage>
    <CardHeader textColor={mainColor}>{header}</CardHeader>
    <CardDetail>{children}</CardDetail>
  </CardFrontStyled>
);

export const CardBackBlock = ({ gradient1, gradient2, children }) => (
  <CardBackStyled gradient1={gradient1} gradient2={gradient2}>
    {children}
  </CardBackStyled>
);

const Card = ({ front: Front, back: Back }) => (
  <CardContainer>
    <Front />
    <Back />
  </CardContainer>
);

export default Card;
