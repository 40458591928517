import React from 'react';

import Modal from '../Modal';


const ModalPortfolio = ({ modalOpen, setModalOpen, item }) => (
    <Modal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        item={item}
        isTier={false}
    />
)

export default ModalPortfolio;




