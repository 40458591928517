import React from 'react'
import styled from 'styled-components'

const Box = styled.div`
    border-radius: 5px;
`

const VideoIframe = ({ youtubeId, bilibiliId }) => {
    let url;
    (youtubeId)
    ? url = `https://www.youtube.com/embed/${youtubeId}?rel=0&amp;autoplay=1&mute=1`
    : url = `https://player.bilibili.com/player.html?aid=${bilibiliId}&mute=1`
    return (
      <Box
        className="video"
        style={{
          position: "relative",
          paddingBottom: "56.25%" /* 16:9 */,
          paddingTop: 25,
          height: 0
        }}
      >
        <iframe
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            borderRadius: '5px'
          }}
          title="ivVideos"
          src={url}
          frameBorder="0"
        />
      </Box>
    );
  };

  export default VideoIframe;