import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { animated } from "react-spring";

import Background from "../../Components/Background";

import memberInformation from "../../utils/MemberInfo/index";
import instructorInformation from "../../utils/InstructorInfo/index";
import ReturnButton from "../../Compositions/ReturnButton";
import HeaderText from "../../Components/HeaderText";
import {DARKPURPLE} from "../../utils/color";

import CollapsibleContainer from "../../Components/Collapsible";
import staffInformation from "../../utils/StaffInfo";
import alumniInformation from "../../utils/AlumniInfo";

const AboutContainer = ({ children }) => (
  <Background
    backgroundImg1x={"/assets/background-iv-text-1x.webp"}
    backgroundImg2x={"/assets/background-iv-text-2x.webp"}
    backgroundImg3x={"/assets/background-iv-text-3x.webp"}
  >
    {children}
  </Background>
);

const DescriptionContainer = styled.div`
  margin: 0 auto;
  width: calc(90% - 1vw);
  flex-grow: 1;
  max-width: 800px;
  padding: 2rem;
`;

// const PlaceHolder = styled.div`
//     width: 40%;
//     height: 800px;
//     flex-grow: 1;
//     background-image url('${window.location.origin}/assets/blacksmith-char-3x.webp');
//     background-repeat: no-repeat;
//     background-size: contain;
//     background-position: top;

//     @media screen and (max-width: 1024px) {
//         display: none;
//     }
// `;

const Space = styled.div`
  height: 5rem;
`;

const Member = styled(animated(Link))`
  text-decoration: none;
  margin-top: 3rem;
  margin-bottom: 2rem;
  height: 10rem;
  background-image: ${(props) =>
      props.imageType === "webp"
        ? `url('${window.location.origin}/assets/about-${props.name}-half-2x.webp')`
        : `url('${window.location.origin}/assets/about-${props.name}-half-2x.webp')`},
    linear-gradient(to right, rgba(83, 73, 97, 0.3), #fffaf1 80%, #fffaf1 80%),
    ${(props) =>
      props.imageType === "webp"
        ? `url('${window.location.origin}/assets/about-${props.name}-half-2x.webp')`
        : `url('${window.location.origin}/assets/about-${props.name}-half-2x.webp')`};
  background-size: 300px, cover, cover;
  background-position: left 20%;
  background-repeat: no-repeat;

  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  padding-right: 1rem;
  box-shadow: 0 0 3rem rgba(0, 0, 0, 0.4);

  :hover {
    transform: scale(1.01);
    box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.4);
    transition: transform 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  }
`;

const MemberName = styled.span`
  display: inline-block;
  // background-image: linear-gradient(
  //   to bottom,
  //   rgba(83,73,97, 0.85),
  //   rgba(83,73,97, 0.7),
  //   rgba(60, 75, 83, 0.3)
  // );
  width: 100%;
  padding-right: 1rem;
  text-align: right;
  font-size: 4rem;
  color: ${DARKPURPLE};
  text-shadow: 0px 0px 3px #ffffff ;
`;

const Description = styled.div`
  background-image: linear-gradient(
    to bottom,
    rgba(188, 198, 204, 0.3),
    rgba(238, 238, 238, 0.3),
    rgba(188, 198, 204, 0.2)
  );
  font-size: 2rem;
  color: white;
  padding: 1em;
`;

const InstructorHeader = styled(animated.div)`
  background-repeat: no-repeat;
  :hover {
    cursor: pointer;
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  }

  background-image: linear-gradient(
    to bottom,
    rgba(188, 198, 204, 0.3),
    rgba(238, 238, 238, 0.3),
    rgba(188, 198, 204, 0.2)
  );
  width: 100%;
  padding-right: 1rem;
  text-align: right;
  font-size: 4rem;
  color: white;
`;

const MemberHeader = styled(InstructorHeader)`
  background-position: 0% 75%;
`;

const AboutMain = () => (
  <AboutContainer>
    <HeaderText title={"about iv"} />
    <DescriptionContainer>
      <Description>
        Iron Vertex was founded by Brian in 2018, with the purpose of gathering
        and training Live2D designers. As the team grew, Brian has been working
        on training members and assisting them to grow their careers as a Live2D
        freelancer.
        <Space />
        Iron Vertex currently consists of 5 active animators, 4 instructors, and 2 additional staff.
        <Space />
        Iron Vertex is currently closed for new members application. If you are
        interested in the application process, please reach out to IV's supporting
        staff at inquiries@ironvertex.com.
      </Description>
      <CollapsibleContainer
        index={0}
        header={MemberHeader}
        headerText={"IV Blacksmiths"}
        initialToggle
      >
        {Object.values(memberInformation).map((member, index) => (
          <Member
            key={index}
            name={member.name}
            imageType={member.imageType}
            to={`/about/${member.name}`}
          >
            <MemberName>{member.displayName}</MemberName>
          </Member>
        ))}
      </CollapsibleContainer>
      <CollapsibleContainer
        index={1}
        header={InstructorHeader}
        headerText={"IV Instructors"}
      >
        {Object.values(instructorInformation).map((member, index) => (
          <Member
            key={index}
            name={member.name}
            imageType={member.imageType}
            to={`/about/${member.name}`}
          >
            <MemberName>{member.displayName}</MemberName>
          </Member>
        ))}
      </CollapsibleContainer>
      <CollapsibleContainer
        index={2}
        header={InstructorHeader}
        headerText={"IV Staff"}
      >
        {Object.values(staffInformation).map((member, index) => (
          <Member
            key={index}
            name={member.name}
            imageType={member.imageType}
            to={`/about/${member.name}`}
          >
            <MemberName>{member.displayName}</MemberName>
          </Member>
        ))}
      </CollapsibleContainer>
      <CollapsibleContainer
        index={3}
        header={InstructorHeader}
        headerText={"IV Alumni"}
      >
        {Object.values(alumniInformation).map((member, index) => (
          <Member
            key={index}
            name={member.name}
            imageType={member.imageType}
            to={`/about/${member.name}`}
          >
            <MemberName>{member.displayName}</MemberName>
          </Member>
        ))}
      </CollapsibleContainer>
    </DescriptionContainer>
    <ReturnButton route={"/"} />
  </AboutContainer>
);

export default AboutMain;
