import React from "react";
import styled from "styled-components";
import Background from "../../Components/Background";

const Container = styled(Background)`
  background-attachment: fixed;
  position: relative;
`;

const TextBaseBgContainer = ({ gradientString, children }) => (
  <Container
    gradientString={gradientString}
    backgroundImg1x={"/assets/background-iv-text-1x.webp"}
    backgroundImg2x={"/assets/background-iv-text-2x.webp"}
    backgroundImg3x={"/assets/background-iv-text-3x.webp"}
  >
    {children}
  </Container>
);

export default TextBaseBgContainer;
