import PopupModal from "../../Components/ModalBase";
import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import VideoIframe from "../../Components/VideoIframe";

import tierInfo from "../../utils/Tier/index";
import { shine } from "../../Animations/keyframes";

const ModalContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 0 auto;
    flex-wrap: wrap;
    background-image: linear-gradient(to right, rgba(0,0,0,0.8) 0%, rgba(2,0,36,1) 100%), url('${(
      props
    ) => props.url}');
    background-position: center;
    background-repeat: no-repeat;
    padding: 1em;
    padding-bottom: 2em;

    box-shadow: 0 1rem 2rem rgba(0,0,0, .2);
`;

const TierDescription = styled.div`
  flex: 1 1 300px;

  display: flex;
  justify-content: center;
  @media screen and (max-width: 600px) {
    flex-direction: column-reverse;
    margin-top: 5%;
  }
`;

const Tier = styled.div`
    flex: 1 1 50px;
    max-width: 85px;
    font-size: 5em;
    color: white;
    align-self: center;
    padding-left: 10px;
    height: 100%;
    background-image:  linear-gradient( 
        to bottom,
        rgba(0,0, 0, 0.7), 
        rgba(0,0, 0, 0.25), 
        rgba(0,0, 0, 0.7)),
        url('/assets/about-${(props) => props.animator}-half-2x.webp');
    
    background-position: 75% 0%;

    @media screen and (max-width: 600px){
        background-position: 0% 50%;
         background-image:  linear-gradient(
        to bottom,
        rgba(0,0, 0, 0.7),
        rgba(0,0, 0, 0.5),
        rgba(0,0, 0, 0.7)),
        url('/assets/about-${(props) => props.animator}-half-2x.webp');
        max-width: 600px;
        width: 95%;
    }
`;

const Project = styled.div`
  flex: 1 1 200px;
  font-size: 2em;
  height: 100%;
  margin-left: 10px;
  margin-right: 10px;

  display: flex;
  flex-flow: column;
`;

const AssetContainer = styled.div`
  flex: 10 1 500px;
`;

const Image = styled.img`
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;

  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
  border-radius: 1px;
`;

const Name = styled.p`
  flex-grow: 0;
  background: linear-gradient(
    to bottom,
    rgba(188, 198, 204, 0.3),
    rgba(188, 198, 204, 0.2)
  );
  padding: 5px 5px 0 5px;
  color: white;
  font-size: 1em;
  text-transform: capitalize;

  :link {
    text-decoration: none;
  }
`;

const NameLeft = styled(Name)`
  margin-top: 10px;
  flex-grow: 10;
  max-width: 135px;
`;

const NameRight = styled(Name)`
  margin-top: 10px;
  flex-grow: 10;

  box-shadow: 0.5rem 1rem 2rem rgba(0, 0, 0, 0.7);
  margin-left: 5px;
  text-align: center;
  padding-right: 15px;
  overflow: hidden;
  :link,
  :hover {
    text-decoration: none;
    cursor: pointer;
    background: ${(props) => props.theme.sub};
  }

  :hover {
    transform: scale(1.05);
    transition: transform 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
    box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.7);

    &:after {
      content: "";
      top: 0;
      transform: translateX(100%) rotate(45deg);

      width: 100%;
      height: 300%;
      position: absolute;
      z-index: 1;
      animation: ${shine} 3s cubic-bezier(0.075, 0.82, 0.165, 1);
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.8) 25%,
        rgba(128, 186, 232, 0) 99%,
        rgba(128, 186, 232, 0) 100%
      );
    }
  }
`;

const DetailsBlock = styled.ul`
  margin-top: 10px;
  color: white;
  flex-grow: 1;
  background: linear-gradient(
    to bottom,
    rgba(188, 198, 204, 0.3),
    rgba(188, 198, 204, 0.2)
  );
  padding: 5px 5px 0 5px;
  & > li {
    padding-top: 5px;
    font-size: 0.8em;
    margin-left: 20px;
  }
  max-height: 100%;
`;

const CreditContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const renderVideoOrImage = (url, imageUrl) =>
  url && url.includes("youtube") ? (
    <VideoIframe youtubeId={url.split("?v=")[1]} />
  ) : url.includes("www.bilibili.com") ? (
    <VideoIframe bilibiliId={url.split("av")[1]} />
  ) : (
    <Image style={{ objectFit: "cover" }} src={imageUrl} />
  );

const Modal = ({ modalOpen, setModalOpen, item, isTier, children }) => (
  <PopupModal modalOpen={modalOpen} setModalOpen={setModalOpen}>
    {(item.animator || item.artist) && (
      <ModalContainer url={`assets/gallery-${item.img}.webp`}>
        <AssetContainer>
          {renderVideoOrImage(item.url, item.src)}
        </AssetContainer>
        <TierDescription>
          <Project>
            <Name as="a" href={item.site} target="_blank">
              {item.name}
            </Name>
            <CreditContainer>
              <NameLeft>Animation :</NameLeft>
              <NameRight
                as={Link}
                to={`/about/${item.animator}`}
                onClick={() => setModalOpen(false)}
              >
                {" "}
                {item.animator}
              </NameRight>
            </CreditContainer>
            <CreditContainer>
              <NameLeft>Art :</NameLeft>
              {item.artist !== "Unknown" ? (
                <NameRight as={"a"} href={item.artist.url} target="_blank">
                  {item.artist.name}
                </NameRight>
              ) : (
                <Name style={{ flexGrow: 1 }}>Unknown</Name>
              )}
            </CreditContainer>
            <DetailsBlock>
              {console.log("item: ", item)}
              Features:
              {isTier
                ? item.tier &&
                  tierInfo[item.tier].map((t) => <li key={t}>{t}</li>)
                : item.feature && item.feature.map((t) => <li key={t}>{t}</li>)}
            </DetailsBlock>
          </Project>
          <Tier animator={item.animator}>{isTier && <>T{item.tier}</>}</Tier>
        </TierDescription>
      </ModalContainer>
    )}
  </PopupModal>
);

export default Modal;
