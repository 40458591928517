import React from "react";
import styled, { ThemeContext } from "styled-components";

import Background from "../../Components/Background";

import ReturnButton from "../../Compositions/ReturnButton";
import HeaderText from "../../Components/HeaderText";

import { sortByTier } from "../../utils/MemberInfo";
import Gallery from "../../Compositions/Gallery";
import hexToRgb from "../../utils/helpers";

const TiersContainer = ({ children }) => {
  const theme = React.useContext(ThemeContext);
  return (
    <Background
      gradientString={`linear-gradient(to right, rgba(${hexToRgb(
        theme.shadow
      )},0.5) 72%,  rgba(${hexToRgb(theme.main)},0.8) 100%) `}
      backgroundImg1x={"/assets/background-iv-text-1x.webp"}
      backgroundImg2x={"/assets/background-iv-text-2x.webp"}
      backgroundImg3x={"/assets/background-iv-text-3x.webp"}
    >
      {children}
    </Background>
  );
};

const DescriptionContainer = styled.div`
  width: calc(95% - 1vw);
  max-width: 800px;
  flex-grow: 1;
  padding: 2rem;
  margin: 0 auto;
`;

const Space = styled.div`
  height: 5rem;
`;

const Description = styled.div`
  background-image: linear-gradient(
    to bottom,
    rgba(188, 198, 204, 0.3),
    rgba(238, 238, 238, 0.3),
    rgba(188, 198, 204, 0.2)
  );
  font-size: 2rem;
  color: white;
  padding: 1em;
`;

const TierContainer = styled.div`
  font-size: 2rem;
  color: white;
  padding-bottom: 20px;
  padding-top: 5em;
`;

const TierTitle = styled.div`
  font-size: 3em;
  text-transform: uppercase;
  text-align: right;
  padding: 20px;
  vertical-align: middle;
  border: 1px solid ${(props) => props.theme.shadow};
  border-bottom: none;
  border-left: none;
`;

const Tiers = () => (
  <TiersContainer>
    <HeaderText
      title={"IV Works"}
      sub={"Iron Vertex Projects, Supervised by Brian Tsui"}
    />
    <DescriptionContainer>
      <Description>
        Iron Vertex categorizes projects base on the range of movements into
        Tier 1 to 5. Generally speaking, Tier 1 (T1) to Tier 4 (T4) are designed
        for Virtual streamers (Vtubers), whereas Tier 5 (T5) is designed for
        game projects.
        <Space />
        For project inquiries, please 
        email us at inquiries@ironvertex.com
        <Space />
        The following demos are debuted projects completed by various Iron
        Vertex members. To ensure quality, all products are supervised by Brian
      </Description>
      <Space />
      {sortByTier.map((tier, index) => {
        return (
          index > 0 && (
            <TierContainer key={`T${index}`} id={`tier${index}`}>
              <TierTitle>T{index}</TierTitle>
              <Gallery gallery={tier} index={index} modaltype={"tier"} />
            </TierContainer>
          )
        );
      })}
    </DescriptionContainer>
    <ReturnButton route={"/"} />
  </TiersContainer>
);

export default Tiers;
