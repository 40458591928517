import React from "react";
import styled, { ThemeContext } from "styled-components";

import { TitleWideText as Title } from "../../Components/Title";

import { FlexCardHorizontal } from "../../Components/GridCards";
import { ButtonMetallic } from "../../Components/Buttons";

import tutorialInfo from "../../utils/TutorialInfo/index";
import { Link } from "react-router-dom";

const Header = styled(Title)`
  margin-top: 4rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  line-height: 3rem;
`;

const TutorialSection = styled.div`
  margin-top: 3rem;
  padding-top: 20px;
  position: relative;
  top: 0px;
  background: ${(props) => props.theme.main};
  padding-bottom: 2em;
`;

// const BackgroundVideo = styled.div`
//     position: relative;
//     // top: 0;
//     // right: 0;
//     z-index: -1;
//     height:
//     overflow-x: hidden;

// `;

// const Video = styled.video`

//     position: absolute;
//     object-fit: cover;
//     top: 0;
//     left:0;

//     filter: blur(3px);
//     opacity: 0.2;

//     min-height: 1300px;

//     min-width: 100vw;

//     object-position: center;
//     overflow-x:hidden;

// `;

const TutorialContainer = styled.div`
  margin: 0 auto;
  width: 70%;
  text-align: center;
  z-index: 300 !important;
  padding-bottom: 2em;
`;

const SmallButton = styled(Link)`
  :link,
  :visited {
    text-transform: uppercase;
    text-decoration: none;

    //is inline-element, bc just text so:
    display: inline-block;

    font-size: 2rem;

    transition: all 0.2s; //animate all

    :hover {
      transform: scale(1.05);
      letter-spacing: 1.15rem;
    }
  }
  color: ${(props) => props.theme.font_light};
  letter-spacing: 1.05rem;
  text-shadow: 1px 1px 1px ${(props) => props.theme.font_dark};
  padding-top: 2rem;
  margin: 0 auto;
`;

const ButtonMetallicPosition = styled.div`
  position: absolute;
  bottom: 1rem;
`;

/**
 *   <BackgroundVideo>
            <Video playsInLine autoPlay muted loop>
                <source src="./assets/tutorial-saber.mp4" type="video/mp4" />
                Your brower does not support this feature.
            </Video>
        </BackgroundVideo>
          <Credit to="https://twitter.com/Saruei_">Video Art By : _Saruei</Credit>
 */

const TutorialHeader = styled(Header)`
  padding-top: 0;
  color: ${(props) => props.theme.font_light};
`;

const Tutorials = () => {
  const theme = React.useContext(ThemeContext);
  return (
    <>
      <TutorialSection>
        <TutorialContainer>
          <TutorialHeader>IV Tutorials</TutorialHeader>
          <FlexCardHorizontal
            reversed={true}
            src={`${window.location.origin}/assets/tutorial-${tutorialInfo[0].instructor}-${tutorialInfo[0].id}.webp`}
            title={tutorialInfo[0].title}
            summary={tutorialInfo[0].summary}
            as="a"
            href={tutorialInfo[0].link}
          >
            <ButtonMetallicPosition
              as="a"
              href={tutorialInfo[0].link}
              style={{ textDecoration: "none" }}
            >
              <ButtonMetallic
                gradientString={` to bottom, ${theme.sub}, ${theme.sub}`}
              >
                View
              </ButtonMetallic>
            </ButtonMetallicPosition>
          </FlexCardHorizontal>

          <FlexCardHorizontal
            src={`${window.location.origin}/assets/tutorial-${tutorialInfo[1].instructor}-${tutorialInfo[1].id}.webp`}
            title={tutorialInfo[1].title}
            summary={tutorialInfo[1].summary}
          >
            <ButtonMetallicPosition
              as="a"
              href={tutorialInfo[1].link}
              style={{ textDecoration: "none" }}
            >
              <ButtonMetallic
                gradientString={` to bottom, ${theme.sub}, ${theme.sub}`}
              >
                View
              </ButtonMetallic>
            </ButtonMetallicPosition>
          </FlexCardHorizontal>

          <SmallButton to="/tutorial">More Tutorials</SmallButton>
        </TutorialContainer>
      </TutorialSection>
    </>
  );
};

export default Tutorials;