import React, { useState } from "react";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";
import { Transition } from "react-spring/renderprops";

const CollapsedContainerStyled = styled(animated.div)`
  margin: 4rem 0 4rem 0;
`;

const CollapsibleContainer = ({
  index,
  header: Header,
  headerText,
  textalign,
  fontsize,
  children,
  initialToggle = false,
}) => {
  const [open, setToggle] = useState(initialToggle);

  const toggleSetting = () => {
    setToggle(!open);
  };
  const enterRight = useSpring({
    transform: "translateX(0px)",
    opacity: 1,
    from: { transform: "translateX(100px)", opacity: 0 },
  });

  const enterLeft = useSpring({
    transform: "translateX(0px)",
    opacity: 1,
    from: { transform: "translateX(-100px)", opacity: 0 },
  });

  return (
    <CollapsedContainerStyled style={index % 2 === 0 ? enterRight : enterLeft}>
      <Header onClick={toggleSetting}>{headerText}</Header>
      <Transition
        items={open}
        from={{ opacity: 0, height: 0 }}
        enter={{ height: "auto", opacity: 1 }}
        leave={[{ opacity: 0 }, { height: 0 }]}
      >
        {(open) =>
          open &&
          ((props) => <animated.div style={props}>{children}</animated.div>)
        }
      </Transition>
    </CollapsedContainerStyled>
  );
};

export default CollapsibleContainer;
