import Modal from "../Modal";
import React from "react";

import tierInfo from "../../utils/Tier/index";

// const DetailsBlock = styled.ul`
// margin-top: 10px;
// color: white;
// flex-grow: 1;
// background: linear-gradient( to bottom,rgba(188,198,204, 0.3), rgba(188,198,204, 0.2));
//     padding: 5px 5px 0 5px;
//     & > li {
//         padding-top: 5px;
//         font-size: 0.8em;
//         margin-left: 20px;

//     }
// max-height: 100%;
// `

const TierModal = ({ modalOpen, setModalOpen, item }) => (
  <Modal
    modalOpen={modalOpen}
    setModalOpen={setModalOpen}
    item={item}
    isTier={item.tier}
  >
    {item.tier && tierInfo[item.tier].map((t) => <li key={t}>{t}123</li>)}
  </Modal>
);

export default TierModal;
