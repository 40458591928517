import styled from 'styled-components';


const SVGLink = styled.a`
    text-decoration: none;
     :hover {
        cursor: pointer;
        transform: scale(1.05);
        transition: fill 0.2s cubic-bezier(0.19, 1, 0.22, 1);
    }
`

export default SVGLink;