import React from "react";
import styled, { css } from "styled-components";

import { spinIn, spinOut, fadeOut } from "../../Animations/keyframes";

import { ReactComponent as Hexagon } from "../../Components/Icon/logo_hexagon.svg";
import { ReactComponent as Cross } from "../../Components/Icon/cross.svg";
import { ReactComponent as Icon } from "../../Components/Icon/logo_flat.svg";
import { Link } from "react-router-dom";
import { Transition, animated } from "react-spring/renderprops";

const spinInAnimation = () => css`
  ${spinIn} 0.6s cubic-bezier(0.755, 0.05, 0.855, 0.06) forwards;
`;

const fadeOutAnimation = () => css`
  ${fadeOut} 0.1s cubic-bezier(0.755, 0.05, 0.855, 0.06) forwards;
`;

const spinOutAnimation = (props) => css`
  ${spinOut} 0.6s cubic-bezier(0.755, 0.05, 0.855, 0.06) ${props.delay} forwards;
`;

const NavigationStyled = styled.div``;

const NaviContainer = styled(animated.div)`
  width: 100%;
  background: ${(props) => props.theme.sub_bright};
  z-index: 900;
  backface-visibility: hidden;
  overflow: hidden;
`;

//The button:
const Label = styled.label`
  width: 7rem;
  cursor: pointer;
  border-radius: 50%;
  position: fixed;
  top: 6rem;
  right: 6rem;
  z-index: 2000; //z-index only works on elements with position defined.
`;

const LinkStyled = styled(Link)`
    display:block;
    text-align: center;
    padding: 10px 0 10px 0;
    font-size: calc(1rem + 2vw);
   
    font-weight: 200;
    text-transform: uppercase;
    :link, :visited {
        color: white;
        text-decoration: none;
        background-image linear-gradient(120deg, transparent 50%, rgba(0,0,0, 0.2) 50%);
        background-size: 250%;
        transition: all .7s;
    }

    :hover, :active{
        background-position: 100%;
        //transform: translateX(1rem);
        text-shadow: 2px 2px 5px rgb(197, 197, 197);
    }
`;

const HexagonStyled = styled(Hexagon)`
  position: absolute;
  top: 0;
  right: 0;
  width: 7rem;
  height: 7rem;
  stroke-width: 5;
  stroke: white;
  fill: none;

  z-index: 2900;
  animation: ${(props) =>
    props.isopen === "true" ? spinInAnimation : spinOutAnimation};
`;

const CrossStyled = styled(Cross)`
  position: absolute;
  top: 25%;
  right: 25%;
  width: 3rem;
  height: 3rem;

  fill: white;

  opacity: 0;
  animation: ${(props) => props.isopen === "true" && spinOutAnimation};
`;

const IconStyled = styled(Icon)`
  position: absolute;
  top: 0;
  right: 0;
  width: 7rem;
  height: 7rem;
  stroke: white;
  stroke-width: 5;
  fill: none;

  animation: ${(props) =>
    props.isopen === "true" ? fadeOutAnimation : spinOutAnimation};
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
`;

const Navigation = () => {
  const [isOpen, setMenuToggle] = React.useState(false);
  const toggle = () => {
    window.scrollTo(0, 0);
    isOpen ? setMenuToggle(false) : setMenuToggle(true);
  };

  React.useEffect(() => {
    const scrollPass = () => {
      let elementTarget = document.getElementById("navi");
      if (
        isOpen &&
        window.scrollY > elementTarget.offsetTop + elementTarget.offsetHeight
      ) {
        setMenuToggle(false);
      }
    };

    window.addEventListener("scroll", scrollPass);

    return () => window.removeEventListener("scroll", scrollPass);
  }, [isOpen]);

  return (
    <NavigationStyled>
      <Label onClick={toggle}>
        <HexagonStyled isopen={isOpen.toString()} />
        <CrossStyled isopen={isOpen.toString()} delay={"0.65s"} />
        <IconStyled isopen={isOpen.toString()} />
      </Label>
      <Transition
        items={isOpen}
        from={{ opacity: 0, height: 0, marginBottom: 0 }}
        enter={{ height: "auto", opacity: 1, marginBottom: "1.5rem" }}
        leave={[{ opacity: 0, marginBottom: 0 }, { height: 0 }]}
      >
        {(isOpen) =>
          isOpen &&
          ((props) => (
            <NaviContainer style={props} id={"navi"} onClick={toggle}>
              <LinkStyled to="/">Home</LinkStyled>
              <LinkStyled to="/about">Members</LinkStyled>
              <LinkStyled to="/tier">Works</LinkStyled>
              <LinkStyled to="/tutorial">Tutorials</LinkStyled>
              <LinkStyled to="/contact">Contact</LinkStyled>
              <LinkStyled to="/faq">FAQ</LinkStyled>
            </NaviContainer>
          ))
        }
      </Transition>
    </NavigationStyled>
  );
};

export default Navigation;
